import { useEffect, useState } from "react";
import { Accordion, Card, Col, Container } from "react-bootstrap";

export const RegulationBG = ({ regulation }) => {
  const head = require("../../assets/standard/head.png");
  const teeth = require("../../assets/standard/teeth.gif");
  const eyes = require("../../assets/standard/eyes.png");
  const bh = require("../../assets/standard/BH.png");
  const [active, setActive] = useState(regulation);
  useEffect(() => {
    setActive(regulation);
  }, [regulation]);

  return (
    <>
      <Container className='mt-4 p-2 justify-content-center pt-5'>
        <Accordion alwaysOpen activeKey={active} onSelect={(e) => setActive(e)}>
          <Accordion.Item eventKey='standard'>
            <Accordion.Button className='accordion-button bg-secondary'>
              FCI-Standard N°144 / 09.07.2008 /
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Header className='mb-3 text-center'>
                  <Card.Title>Германски боксер</Card.Title>
                  <Card.Subtitle>(Deutscher Boxer)</Card.Subtitle>
                  <Col xs={6} md={4} className='m-auto'>
                    <Card.Img className='mt-3 ' src={head} alt='Card image' />
                  </Col>
                </Card.Header>
                <Card.Text>
                  Превод: Г. Великова, редакция Д-р Б. Великов.
                </Card.Text>
                <Card.Text>Произход: Германия.</Card.Text>
                <Card.Text>
                  Дата на публикуване на валидния стандарт: 13.03.2001.
                </Card.Text>
                <Card.Text>
                  Предназначение: Компаньон, пазач и служебно куче.
                </Card.Text>
                <Card.Text>
                  F.C.I.Класификация: Група 2 Пинчери и шнауцери- Молосоидни
                  породи- Швейцарско планинско, Пастирски кучета и други породи.
                  Секция 2.1 Молосоидни породи, тип мастиф. С работни изпитания.
                </Card.Text>
                <Card.Text>
                  Кратка историческа справка:Малкият т.нар Брабантски
                  буленбайсер е приет за родоначалник на боксера. В миналото
                  тези кучета са били развъждани от ловците, тъй като са били
                  най-добрият помощник в лова. Тяхната задача е била да уловят и
                  задържат вече вдигнатата от гончетата плячка и да я задържат
                  до идването на ловеца. За тази задача са били необходими
                  кучета с възможно най-широко разположение на челюстите и
                  зъбите, за да могат да захапят здраво и дълго да задържат.
                  Буленбайсерът е притежавал тези характеристики и е бил широко
                  разпространен и развъждан, но разбира се само за гореописаното
                  предназначение. Селекцията започната в края на 19ти век е
                  причина за днешния изглед на боксера - куче с широка муцуна и
                  леко вирнат нос.
                </Card.Text>
                <Card.Text>
                  Общ изглед: Боксерът е средно голямо куче с гладка козина,
                  здраво, мускулесто и с добра костна система. Той има добре
                  изразени, силно развити и релефни мускули. Подвижен, игрив и
                  силен, той не би трябвало да изглежда нито тромав, нито пълен,
                  нито слаб.
                </Card.Text>
                <Card.Text>
                  1. Пропорции:
                  <br /> a) Дължина на тялото към височината при холката:
                  квадратни очертания, т.е. хоризонталната линия на гърба и
                  двете вертикални линии към земята, едната тръгваща от рамото,
                  а другата от бута, образуват квадрат.
                  <br /> b) Дълбочина на гърдите към височината при холката:
                  Гръдният кош стига до лактите. Дълбочината на гърдите е равна
                  на половината от височината при холката.
                  <br /> c) Дължина на извивката на носа към дължината на
                  главата: Дължината на извивката на носа спрямо черепа трябва
                  да бъде 1 : 2 (измерена от върха на носа до вътрешния ъгъл на
                  окото или съответно от вътрешния ъгъл на окото до тила.
                </Card.Text>
                <Card.Text>
                  Поведение /Темперамент: Боксерът трябва да е спокоен,
                  самоуверен и уравновесен. Темпераментът е от изключително
                  значение и изисква голямо внимание. Той е известен с
                  предаността и верността към господаря и цялото му семейство, с
                  бдителността и самоотвержеността си при защита. Безобиден е за
                  семейството си, но е подозрителен към непознати, весел и
                  дружелюбен при игра, но безстрашен в сериозна ситуация.
                  Податлив е за обучение поради желанието си да се подчинява,
                  смелостта си, природната интелигентност и чувствителното си
                  обоняние. Непридирчив и чист, той е толкова симпатичен и ценен
                  за семейния кръг, колкото и като пазач, придружител или
                  работно куче. Характерът му е стабилен, непристорен и открит
                  дори и в напреднала възраст.
                </Card.Text>
                <Card.Text>
                  Глава: Тя придава характерния вид на боксера. Трябва да е
                  пропорционална за тялото и да не изглежда нито твърде малка,
                  нито твърде голяма. Муцуната трябва да бъде колкото е възможно
                  по-широка и силна. Красотата на главата зависи от
                  пропорционалността на муцуната и главата. От какъвто и ъгъл да
                  се гледа главата - във фас, профил или отгоре - муцуната
                  трябва винаги да бъде пропорционална на главата, т.е. да не
                  изглежда твърде малка. Тя трябва да е изпита, без бръчки.
                  Бръчките, обаче, се появяват, когато ушите се наострят или,
                  ако ушите не са купирани, когато кучето застане нащрек.
                  Гънките винаги са насочени от основата на муцуната надолу към
                  страните. Тъмната маска се намира само върху муцуната и трябва
                  ясно да контрастира с цвета на главата, така че изражението на
                  лицето да не изглежда мрачно.
                </Card.Text>
                <Card.Text>
                  Череп: Черепът трябва да е максимално деликатен и ъгловат -
                  леко извит, нито закръглен и малък, нито плосък, нито твърде
                  широк или пък твърде с твърде изразена задна част. Предната
                  вдлъбнатина, леко очертана, не трябва да е твърде дълбока
                  особено между очите <br />
                  Стоп: Челото образува характерен стоп с горната граница на
                  муцуната. Извивката на носа не трябва да бъде вдлъбната назад
                  към челото, както е при булдозите, нито да сочи надолу.
                </Card.Text>
                <Card.Text>
                  Лицева част <br />
                  Нос: Носът е широк, черен, леко извит нагоре с широки ноздри.
                  Върхът на носа е поставен малко по-нагоре от основата.
                  <br />
                  Муцуна: Муцуната е силно изразена в три измерения, не е нито
                  изострена, нито тясна, малка или плитка. Определя се от:
                  <br />
                  a) формата на челюстите; <br />
                  b) разположението на кучешките зъби; <br />
                  c) положението на устните.
                  <br />
                  Кучешките зъби трябва да са достатъчно дълги и максимално
                  отдалечени един от друг, така че лицевата част на муцуната е
                  широка, почти квадратна и образува тъп ъгъл с горната граница
                  на муцуната. <br />
                  Отпред ръбът на горния зъб опира на ръба на долната устна.
                  Частта от долната челюст, която е леко извита нагоре, заедно с
                  долната устна се нарича брадичка. Брадичката може да не е ясно
                  изразена под долната устна или дори да изчезва под нея, но
                  трябва да може да вижда отпред и отстрани. Кучешките зъби и
                  резците на долната челюст не трябва да се виждат, когато
                  устата е затворена, нито пък трябва да се вижда езика на
                  боксера в същото положение. Назолабиалният ръб е ясно видим.
                  <br /> Устни: Увисналите устни довършват изгледа на муцуната.
                  Горната устна е плътна и запълва мястото, образувано от
                  долната челюст. На устните се опират долните кучешки зъби.
                  <br /> Челюсти/Зъби: Долната челюст е издадена пред горната и
                  е леко извита нагоре. Боксерът е с обратна захапка. Горната
                  челюст е широка там където се свързва с черепа и леко се
                  скосява напред. Зъбите са здрави и силни. Резците са равни и
                  подредени в права линия, докато кучешките са раздалечени и
                  големи.
                </Card.Text>
                <Card.Img className='mt-2 mb-2' src={teeth} alt='Card image' />
                <Card.Text>
                  Бузи: Те са пропорционални спрямо здравите челюсти, без да се
                  издават подчертано. Сливат се в лека извивка към муцуната.
                  <br />
                  Очи: Очите са тъмни, средно големи, не са изпъкнали, нито
                  дълбоко разположени. Изразяват енергия и интелигентност, а не
                  заплаха или упоритост. Рамките на очите са тъмни.
                </Card.Text>
                <Col xs={10} md={6} className='m-auto'>
                  <Card.Img className='p-5' src={eyes} alt='Card image' />
                </Col>
                <Card.Text>
                  Уши: Поставени са високо. Имат подходяща големина, по-скоро
                  малки, отколкото големи и тънки. Раздалечени са едно от друго
                  и поставени на най-високите места на черепа. Лежат спокойно до
                  бузите в спокойно състояние и се накланят напред с изразена
                  гънка, когато кучето застане нащрек. (FCI - 02/04/2001)
                  <br />
                  Врат: Горната линия е елегантно извита от ясно видимия тил към
                  холката. Трябва да е достатъчно дълъг, объл, здрав, мускулест
                  и ясно изразен.
                  <br />
                  Тяло: Квадратно тяло, което се носи от яки и прави крака.{" "}
                  <br />
                  Холка: Трябва да е добре изразена.
                  <br />
                  Гръб: Гърбът и слабините трябва да са къси, твърди, прави,
                  широки и добре замускулени. <br />
                  Бутове: Леко наклонени, широки и малко извити. Тазът трябва да
                  е дълъг и широк, особено при женските. <br />
                  Гръден кош Широк, достигащ до лактите. Дълбочината му е равна
                  на половината от височината при холката. Добре развит в
                  предната си част. Ребрата са ясно изразени, но не са
                  цилиндрично разположени и се простират до гърба. <br />
                  Коремна линия: Извива се елегантно към гърба. Къси твърди
                  хълбоци, леко изпънати назад. <br />
                  Опашка: Поставена е по-скоро високо отколкото ниско, оставя се
                  в естествения и вид.(FCI - 02/04/2001)
                </Card.Text>
                <Card.Text>
                  Предни крака: Предните крака трябва да са прави, успоредни
                  един на друг гледани отпред и да имат здрави кости.
                  <br />
                  Рамене: Дълги и наклонени, неотделна част от тялото. Не са
                  изключително замускулени.
                  <br />
                  Проксимална част от краката: Дълга, образуваща прав ъгъл с
                  раменете.
                  <br />
                  Лакти: Нито твърде много притиснати, нито твърде отдалечени от
                  гръдната стена.
                  <br />
                  Дистална част от краката: Права, дълга, слабо замускулена.
                  <br />
                  Китки: Здрави, добре изразени, но не много окръглени.
                  <br />
                  Глезени: Къси почти перпендикулярни към земята.
                  <br />
                  Предни лапи: Малки, кръгли, стегнати с твърди възглавнички.
                  <br />
                  Задни крака: Много мускулести. Мускулите са здрави, добре
                  оформени. Гледани отзад задните крака трябва да са прави.
                  <br />
                  Проксимална част на бедрото: Дълга и широка. Ъгълът, образуващ
                  се между тазовия пояс, проксималната част на бедрото и
                  коленната става, е колкото се може по-малко тъп.
                  <br />
                  Коленна става: Когато кучето е нормално изправено, тя трябва
                  да достига толкова напред, че да се образува вертикална линия
                  от бедрената кост към земята.
                  <br />
                  Дистална част на бедрото: Много мускулеста.
                  <br />
                  Ахилесово сухожилие: Здраво, добре изразено, а върхът му не е
                  обърнат нагоре. Ъгълът е приблизително 140°.
                  <br />
                  Глезени на задните крака: Малки, с лек наклон от 95 - 100° към
                  земята.
                  <br />
                  Задни лапи: Малко по-дълги от предните. Стегнати с твърди
                  възглавнички.
                  <br />
                  Походка - движение: Жива - пълна с енергия и благородство.
                  <br />
                  Кожа: Стегната, еластична, без бръчки.
                  <br />
                  Козина: Къса, твърда, лъскава и плътно прилепнала.
                  <br />
                  Цвят: Пясъчен или тигров. Пясъчното се среща в разнообразни
                  нюанси - от русо пясъчно до тъмно червено, но най-красивото е
                  по средата (червеникавопясъчно). Черна маска. Тигровият
                  вариант в гореспоменатите оттенъци на пясъчно се изразява в
                  тъмни или черни ивици по посока на ребрата. Основният цвят и
                  ивиците трябва да се отличават/контрастират. Белите петна не
                  следва да се отричат изцяло, защото могат да бъдат много
                  красиви.
                  <br />
                  Височина: Измерена от холката покрай лактите до земята: За
                  мъжкари: 57 - 63 см. За женски: 53 - 59 см.
                  <br />
                  Тегло: За мъжкари: над 30 кг (височина приблизително 60 см от
                  холката) За женски: около 25 кг (височина приблизително 56 см
                  от холката)
                  <br />
                  Недостатъци: Всяко отклонение от горните пунктове следва да се
                  счита недостатък и сериозността, с която се възприема, трябва
                  да съответства на степента на отклонение.
                  <br />
                  • Поведение/Темперамент: Агресивност, злоба, пристореност,
                  непостоянство, безхарактерност, страхливост.
                  <br />
                  • Глава: Глава лишена от изразителност и благородство. Мрачно
                  изражение. Глава тип пинчер или булдог. Слюноотделяне,
                  показване на зъбите или езика. Твърде изострена или светла
                  муцуна. Наклонена извивка на носа. Гумен нос (лъскав, но не
                  зърнест на пипане). Временна липса на пигментация на носа
                  поради климатични фактори или бледа кожа на носа. Наличие на
                  т.н. "орлово око", трети клепач (мигателна ципа) без
                  пигментация. Зле купирани уши. Ако не са купирани, наклонени
                  назад, полу или изцяло изправени уши, "булдожки" уши. Крива
                  уста, полегати зъби, неправилно разположение на зъбите. Слабо
                  развити зъби. Неизползваеми зъби вследствие на заболяване.
                  <br />
                  • Врат: къс, дебел, увиснал.
                  <br />
                  • Тяло: Твърде широко или ниско отпред. Хлътнал или извит
                  гръб, слаб гръб. Дълги, тесни или рязко смъкнати, или слабо
                  свързани слабини. Извита лумбална област. Увиснала крупа,
                  тесен таз, увиснал корем, слаби хълбоци. Ниско поставена
                  опашка.
                  <br />
                  • Предница: Предница тип Чипъндейл, приведени рамене,
                  отпуснати лакти, слаби глезени, дълги, тесни "заешки" крака,
                  плоски, изкривени навън крака.
                  <br />
                  • Задница: Слаби мускули. Твърде силно или слабо изразена
                  ъгловатост в задните крака. Криви крака, ахилесови сухожилия
                  като при кравите, тясно събрани ахилесови сухожилия,
                  рудиментарни палци на лапите, дълги, тесни "заешки" крака,
                  плоски, изкривени навън крака.
                  <br />
                  • Походка - движение: Поклащаща се, патешка походка,
                  неустойчива, бавна, надута походка.
                  <br />
                  • Цвят: Маската се простира отвъд муцуната. Тигровите ивици са
                  твърде близко една до друга или само някои от тях са
                  различими. Основен цвят - мръсен. Преливащи цветове. Некрасиви
                  бели петна, като например цялата или половината глава е бяла.
                  Други цветове или кучета, чийто бял цвят надвишава една трета
                  от основния.
                  <br />
                  Дисквалифициращи белези
                  <br />
                  Агресивност или прекалена боязливост.
                  <br />
                  Родени с пречупена или къса опашка
                  <br />
                  N.B. Мъжките животни трябва да имат два, видимо нормални
                  тестиси напълно прибрани в скротума.
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='AD'>
            <Accordion.Button className='accordion-button bg-secondary'>
              Тест за физическа издържливост (ТФИ)
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  <b>Обща информация</b>
                  <br />
                  ТФИ се използва за регистриране на физическата издържливост,
                  условие за развъдна годност. Кучето трябва да пробяга
                  дистанция от 20 км. Процедурата гарантира физическата сила и
                  издържливост. Кратък тест за подчиняемост завършва
                  изпитанието.
                  <br />
                  <b>Изисквания за участие</b>
                  <br />
                  Минималната възрастова граница за участие е 16 м. кучета
                  по-възрастни от 6 год. не се подлагат на това изпитание.
                  Всички участници представят документи за произход, за да бъдат
                  допуснати до изпита. Кучетата трябва да са здрави и в добра
                  кондиция. Не се допускат за участие: болни кучета, слаби
                  кучета, разгонени женски, скоро родили и кърмещи женски.
                  Секретаря и съдията определят кондицията на кучетата
                  участници. Водачът трябва да се представи спортменски.
                  Неспортсменски прояви могат да доведат до дисквалификация.
                  Решението на съдията е последно и окончателно. Участието в
                  тези изпитания е на доброволни начала. БК не носи отговорност
                  ако по време на такива изпитания, водачът или кучето се
                  наранят.
                  <br />
                  <b>Оценяване</b>
                  <br />
                  Сертификатът за издържан тест за издържливост не е документ за
                  обучение. Изпитът не дава точки, оценките са "Издържал" и "Не
                  издържал".
                  <br />
                  <b>Терен за провеждане</b>
                  <br />
                  За предпочитане е тестът да се провежда по улици или
                  асфалтирани алеи.
                  <br />
                  <b>Провеждане на изпита</b>
                  <br />
                  A.) Пробег - Кучетата трябва да пробягат 20 км. със средна
                  скорост от 12 до 15 км в час.
                  <br />
                  Кучетата трябва бягат на повод в дясно на велосипед. Да се
                  избягва от твърде бърза скорост. Поводът да е достатъчно дълъг
                  за да дава възможност да се коригира скоростта на движение.
                  Леко придърпване е допустимо но не постянно, забавянето от
                  поддържаната средна скорост може да доведе до лоша оценка.
                  След пробег на първата дистанция от 8 км. се прави 15 мин.
                  почивка. По време на почивката съдията проверява показателите
                  на умора. Ясно изразени признаци на умора водят до изключване
                  от по-нататъшно участие. Следващата почивка е 20 мин. и е след
                  пробега на 15-я км. Съдията прави отново проверка. Уморени или
                  такива с разранени лапи се изключват от изпитанието.
                  Последните 5 км се пробягват и тогава е последния тест от
                  съдията за разранени лапи и признаци на умора.
                  <br />
                  Съдията и секретарят на изпитанието трябва да придружават
                  кучетата на велосипед или автомобил. Съдията прави бележки за
                  състоянието на кучетата и отбелязва всички недостатъци.
                  Необходимо е кола да следва водачите и кучетата, така че
                  уморените кучета да могат да бъдат транспортирани. Кучетата,
                  на които липсват на подходящия дух и твърдост, и тези, които
                  показват умора и кучета, които не могат да поддържат
                  необходимата скорост или се нуждаят от допълнително време, не
                  могат да преминат изпита.
                  <br />
                  B.) След бягането и по искане на съдията, водачите ще се
                  съберат с кучетата си в позицията редом. Под ръководството на
                  съдията, водачите и кучетата ще извършат упражнението (с повод
                  или без повод) в съответствие с правилата на IPO1. Не трябва
                  да се изстрелват изстрели. Освен това, съдията трябва да
                  провери психологическото състояние на кучетата.
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='BH'>
            <Accordion.Button className='accordion-button bg-secondary'>
              Куче придружител
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  Този тест е създаден като средство за предварителна оценка на
                  характера. Той има за цел да отстрани агресивни, напрегнати,
                  плашливи или нервни кучета от спорта. Според правилата могат
                  да участват кучета от всички размери и породи; като
                  минималната възраст е 12 месеца. Допуска се единствено душащ
                  нашийник, а поводът, когато се използва, се закрепва съм
                  свободната халка. Обикновено точки не се обявяват, по-скоро
                  съдията преценява дали екипът водач-куче е преминал теста.
                  Успешното полагане на част А допуска явяване на част Б.
                  <br />
                  <b>Част А</b>
                  <br />
                  Фазата подчинение от теста е същата, както при стандарт IPO 1,
                  но без упражнението за носене (апорт).
                  <br />
                  Упражненията за подчинение трябва да покажат връзката между
                  водача и кучето. Кучето трябва да демонстрира желание за
                  работа и истинска радост, че е на открито пространство.
                  Упражненията за подчинение се правят при участието на две
                  групи водачи-кучета. И двата отбора докладват на съдията
                  своите имена и тези на кучетата. След това съдията изпраща
                  отборите към определените им места. Докато единият отбор
                  изпълнява ходене, другият демонстрира продължително лежане на
                  място. Всяко упражнение на повод трябва да се прави на хлабав
                  повод. Поводът се държи с лява ръка и е закрепен към
                  свободната халка на душащия нашийник. Кожени или строги
                  нашийници не се допускат.
                  <br />
                  Ходене на повод (15 точки) поединично и в група
                  <br />
                  Кучето трябва да е непосредствено до водача, така че рамото да
                  е наравно с коляното на водача. Кучето не бива да се спуска
                  напред, да се дърпа настрани или да изостава. При спиране
                  трябва да сяда без команда. Единствената гласова команда се
                  дава в началото на упражненията и при смяна на ритъма.
                  <br />
                  В изходна позиция за ходене с кучето от лявата ви страна,
                  дайте гласова команда за тръгване (кучето трябва да тръгне
                  доброволно) и се движете в права линия около 40 -50 крачки без
                  спиране.Обърнете се кръгом и се върнете 10 – 15 крачки назад.
                  Дайте команда бегом и пробягайте 10-15 крачки, после
                  продължете с по-бавно темпо 10 -15 крачки. Възстановете
                  нормалното движение за 10 – 15 крачки. Командата „в крак” може
                  да се подава при всяка смяна на темпото. След това завийте
                  надясно, изминете 20 крачки, отново завийте надясно, нови 20
                  крачки, после кръгом, още 10- 15 крачки и спрете. Изминете
                  10-15 крачки и завийте наляво. Сега можете да се присъедините
                  към групата. Съдията ще насочи водача през група от около
                  четирима души, които се движат наоколо. Трябва да направите
                  най-малко един ляв и един десен завой покрай поне двама души и
                  да спрете в близост до една от групите. (Това фактически е
                  осморка.) След това напускате групата, спирате (водачът може
                  да похвали кучето) и снемате повода.
                  <br />
                  Ходене без повод (15 точки) поединично и в група.
                  <br />
                  Когато съдията поиска, поводът се снема в изходната позиция.
                  Водачът преминава през групата с кучето, което се движи
                  свободно до него. След като изпълнят поне едно спиране,
                  водачът и кучето могат да напуснат групата и да демонстрират
                  упражненията за ходене този път без повод. Докато правят това,
                  се дават два изстрела (от пистолет калибър: 6 – 9 мм), но не
                  докато се движат в групата и кучето трябва да остане
                  безразлично към шума. Изключително важно е кучето да не се
                  стресне от изстрелите. Ако е несигурно по преценка на съдията
                  или ако побегне при изстрелите, то бива освободено от
                  по-нататъшни изпитания.
                  <br />
                  Седеж (10 точки)
                  <br />
                  От изходната позиция за ходене водачът и кучето се придвижват
                  в права линия без повод. След най-малко 10 крачки водачът
                  подава гласова команда „седни” – кучето трябва незабавно да
                  седне. Водачът продължава още поне 30 крачки, без да променя
                  скоростта или посоката, след което спира и се обръща към
                  кучето. По нареждане от съдията той се връща и застава до
                  дясната страна на кучето.
                  <br />
                  Лежане с повикване (10 точки)
                  <br />
                  От изходната позиция за ходене водачът и свободно движещото се
                  до него куче се придвижват в права линия. След най-малко 10
                  крачки водачът подава гласова команда „легни” и то трябва
                  незабавно да се подчини. Водачът продължава още поне 30
                  крачки, без да променя скоростта или посоката, след което
                  спира и се обръща към кучето. По нареждане от съдията той
                  трябва да повика кучето. То трябва да отреагира бързо и живо и
                  да седне пред него. При команда за ходене кучето бързо трябва
                  да заеме мястото си до него.
                  <br />
                  Продължително лежане плюс дразнители (10 точки)
                  <br />
                  Преди да започне упражнението за подчинение на друго куче,
                  водачът заповядва на кучето да легне на място, определено от
                  съдията. След това се отдалечава на приблизително 40 крачки от
                  кучето, но така че то да продължава да го вижда и застава с
                  гръб към него. Кучето трябва да остане в тази позиция без
                  допълнителни насочвания от водача, докато другото куче не
                  изпълни първите 6 упражнения. Завършекът е както при
                  упражненията „легни” или „продължи”.
                  <br />
                  <b>Част Б</b>
                  <br />
                  Тя се състои от изпитания за способността на кучето да се
                  движи при натоварено движение. Тези упражнения се извършват на
                  открито в близост до път, но без да се пречи на движението. По
                  тази причина само кучета, преминали част А, могат да бъдат
                  подложени на теста. Той е доста продължителен, затова максимум
                  15 кучета на ден могат да бъдат подложени на изпитание. Не се
                  присъждат точки за всяко упражнение, а съдията оценява
                  поведението на кучетата и тяхната способност да се справят в
                  упражненията.
                  <br />
                  Държане на кучето при движение. Водачът и кучето получават
                  указание да се движат по определена алея в парка, улица или
                  път. Кучето се движи на хлабав повод до водача. То не трябва
                  да реагира на преминаващите – били те пешеходци или бегачи.
                  След като преодолеят движението по пътя, водачът и кучето
                  отиват до съдията. Докато хората се здрависват и разговарят,
                  кучето трябва да остане безразлично към съдията и да стои,
                  седи или лежи неподвижно.
                  <br />
                  Поведение на кучето при натоварено движение. Сега водач и куче
                  преминават през доста натоварено и шумно движение на
                  пешеходци. Водачът трябва да спре два пъти, веднъж – да нареди
                  на кучето да седне, а втория – да даде команда „легни”. Кучето
                  трябва да остане спокойно и невъзмутимо.
                  <br />
                  Поведение на куче оставено само при натоварено движение.
                  Водачът завързва кучето на подходящо място и го оставя така,
                  скрит от погледа му, за около две минути. През това време на
                  около пет крачки ще премине друг отбор, но вързаното куче
                  трябва да остане спокойно по време на упражнението.
                </Card.Text>
                <Col xs={6} md={4} className='m-auto'>
                  <Card.Img src={bh} alt='Card image' />
                </Col>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='SchH'>
            <Accordion.Button className='accordion-button bg-secondary'>
              Schutzhund ( Охрана )
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  Паралелно с развиване на чистопородните белези и работа за
                  постигане на стандарта, е било установено, че работните
                  качества са от особено значение. Изработената система за
                  изпитания и покриването на тестовата програма е гарантирало
                  охранителните качества на кучетата издържали изпита. Тези
                  гаранции са били от особена важност за допускането до
                  развъждане.
                  <br />
                  Изпитатията за охрана изискват преминаването на три изпита.
                  <br />
                  1. Проследяване
                  <br />
                  2. Подчинение
                  <br />
                  3. Охрана
                  <br />
                  Правилно изпълнявана и от кучета с подходящ темперамент и
                  предназначение, системата от упражнения доставя отлични
                  условия за трениране на охранителните качества. Провеждана от
                  професионалисти, системата може да възпита самочуствието на
                  стопанина и кучето и да ги научи на адекватни реакции в
                  кризисни ситуации. Неправилното и прилагане обаче може да
                  причини големи щети върху психиката на незрели или млади
                  кучета и в най-лошия случай да доведе до хиперагресивност и
                  неконтролируемост на вашия любимец. Треньорите и помощниците
                  за обучение и провеждане на системата трябва да са внимателно
                  подбирани и доказано компетентни.
                  <br />В заключение може да се каже, че това е спорт за всички,
                  които искат да имат добре контролирани и възпитани четириноги
                  приятели. За тези които добре разбират системата и нейното
                  предназначение, това е начин да постигнат хармония със своя
                  партньор и заедно да станат побеждаващ отбор! За развъдчиците,
                  това е програма която пълноценно тества инстинктите, нервната
                  ситема и физическите възможности, така необходими за една
                  работна порода, каквато е и боксера!
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='IPO1'>
            <Accordion.Button className='accordion-button bg-secondary'>
              Изпитен правилник (IPO І)
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  Дисциплини:
                  <br />
                  “А”- следа - 100т. <br />
                  “В” - послушание - 100т. <br />
                  “С” - защита - 100т. <br />
                  Общо: - 300т. <br />
                  “А”- следа - 100т.
                  <br />
                  Дисциплина А - “СЛЕДА” Следата се полага от водача на кучето
                  (самостоятелна следа), не по-къса от 300 крачки, 3 прави
                  отсечки, 2 прави ъгъла (90°), 2 предмета, принадлежащи на
                  водача, и поставена не по-малко от 20 м. преди старта. Време
                  за изпълнение - 15 мин. <br />
                  Преминаване по трасето - 80 точки. <br />
                  Предмети - (10+10) - 20 точки. <br />
                  Общо - 100 точки. <br />
                  След оглед на предоставения терен, съдията и отговорникът по
                  трасирането определят място за полагане на следата за всеки
                  участник, като различните трасета не трябва да се пресичат или
                  да са много близко едно до друго. Съдията определя къде да се
                  поставят предметите, както и посоката на ъглите (ляв или
                  десен). <br />
                  Началото на следата трябва да е добре обозначено със знак,
                  който е поставен на земята вляво от нея.
                  <br />
                  Преди трасирането всеки участник показва предметите на
                  съдията, като същите не трябва да са били във водача поне от
                  30 мин. <br />
                  След кратък престой на началната точка водачът преминава по
                  трасето, обозначено от съдията, с нормални крачки, без да
                  провлачва краката и без да прекъсва своя ход. По същия начин
                  се трасират и ъглите. Първият предмет се поставя след минимум
                  100 крачки на І-та или ІІ-та отсечка. Вторият предмет се
                  полага на края на следата. Предметите се полагат от движение
                  (не е разрешено спиране за поставяне на предметите). След
                  поставянето на втория предмет водачът продължава още няколко
                  крачки по права линия преди да напусне терена. <br />
                  Предметите трябва да са от различен материал, като например:
                  кожа, текстил, дърво и т.н. Същите трябва да са с дължина не
                  повече от 10 см., ширина 2-3 см., дебелина 0,5-1см. и не
                  трябва да се отличават по цвят от околната среда. Кучето не
                  трябва да вижда трасирането на следата. <br />
                  При преминаване на трасето съдията и придружаващият го не
                  трябва да разговарят, за да не пречат и разсейват водача и
                  кучето. <br />
                  а) команда “Търси” (Suchen):
                  <br />
                  Командата е разрешена при започване и след обозначаването на
                  първия предмет;
                  <br />
                  в) изпълнение:
                  <br />
                  Водачът подготвя кучето си за следа. Проследяването може да
                  бъде с повод с дължина 10м. или без повод. Поводът може да е
                  отгоре, отстрани или отдолу м/у предните и задните крака на
                  кучето. Може да се използва нагръдник за следа или обикновен
                  тренировъчен нашийник, но не “душач”. <br />
                  След повикване от съдията водачът се представя със своето куче
                  (кучето е в основно положение) и обявява дали кучето взима
                  предметите или ги обозначава. По знак на съдията кучето се
                  отвежда бавно и спокойно към началото на трасето и започва
                  преминаването по него. Преди, в началото и през цялото време
                  на преминаване по трасето се избягва всякакво принуждаване на
                  кучето. При започване на следата кучето трябва интензивно,
                  спокойно и с нос опрян почти до земята (дълбок нос) да поеме
                  миризмата. Проследяването на трасето трябва да продължи по
                  същия начин - с нос, близко до земята, интензивно и равномерно
                  темпо. Когато кучето започне да се движи, водачът остава
                  неподвижен, докато се изпъне 10-метровият повод. <br />
                  При проследяване без повод се спазва същото разстояние от 10м.
                  м/у кучето и водача. При претърсване с повод не е задължително
                  поводът да е изпънат, той може и да е леко отпуснат. <br />
                  Кучето трябва да проследи и премине ъглите сигурно, след което
                  да продължи напред със същото темпо. Веднага щом кучето е
                  намерило първия предмет, трябва да го вземе или ясно да го
                  обозначи без каквато и да е помощ от водача. <br />
                  При взимане на предмета кучето може да остане право, да седне
                  или да го занесе на водача. Грешно е кучето да върви напред
                  или да легне с предмета в уста. <br />
                  При обозначаване на предмета кучето може да легне, да седне
                  или да е право (разрешено е всеки предмет да се обозначи по
                  различен начин). След като кучето е намерило предмета, водачът
                  пуска повода, отива към кучето и показва намерения предмет,
                  като го вдига високо във въздуха. Проследяването продължава от
                  мястото, където е намерен първият предмет. След завършване на
                  трасето намерените предмети се представят на съдията. <br />
                  с) Оценяване: <br />
                  Бързината, с която се проследява трасето, не е критерий за
                  оценяване, ако кучето търси интензивно, равномерно и сигурно,
                  както и когато кучето показва положително следово поведение
                  (сигурно претърсване, без да излиза от трасето). <br />
                  Грешки: Системно излизане от трасето, “висок нос” (носът не е
                  близо до земята), замърсяване на трасето, обикаляне на ъглите,
                  непрекъснати подканяния, помощ чрез повода, както и вербална
                  такава от страна на водача. Неправилно взимане или
                  обозначаване на предмета. <br />
                  Ако водачът напусне трасето на разстояние повече от един
                  следови повод (10м.), претърсването се прекратява. Ако кучето
                  напусне трасето и водачът се опита да го задържи, съдията дава
                  нареждане водачът да следва кучето. Не се ли изпълни
                  нареждането на съдията, претърсването се прекратява от
                  изпитващия. <br />
                  Ако до 15мин. след началото не е достигнат края на трасето,
                  претърсването се прекратява по нареждане на съдията.
                  Извършената работа до прекратяването се оценява. <br />
                  Показването на предметите чрез двата възможни начина - взимане
                  и обозначение - се счита за грешка. Оценяват се само
                  предметите, които са показани по начина, посочен от водача
                  преди започване на претърсването. За необозначени или невзети
                  предмети не се дават точки. Разделянето на точките за
                  претърсване на трасето, както и за ъглите, трябва да се
                  оценява в зависимост от дължината и трудността. Оценяването на
                  всеки ъгъл поотделно се извършва чрез оценки и точки. Ако
                  кучето не търси (дълго време стои на едно място без да души),
                  може да се прекрати претърсването, дори и то да се намира на
                  трасето. <br />
                  “В” - послушание - 100т.
                  <br />
                  Дисциплина В - “ПОСЛУШАНИЕ” <br />
                  Упражнение:
                  <br />
                  1. Ходене наред без повод - 20т.
                  <br />
                  2. Сядане от движение - 10т.
                  <br />
                  3. Лягане от движение с повикване - 10т.
                  <br />
                  4. Донасяне на предмет от равен терен - 10т.
                  <br />
                  5. Донасяне на предмет през 1м. препятствие - 15т.
                  <br />
                  6. Донасяне на предмет през “А”-обр. препятствие - 15т.
                  <br />
                  7. Изпращане напред с лягане - 10т.
                  <br />
                  8. Престой - 10т.
                  <br />
                  Общо: 100 точки
                  <br />
                  Общи положения Съдията дава знак на водача да започне
                  упражнението. Всичко останало, обръщания, спиране, смяна на
                  темпото и т.н. се изпълняват без указания от страна на
                  съдията. Разрешените команди са описани във всяко упражнение
                  поотделно. Те са нормално изговорени, къси и състоящи се от
                  една дума. Могат да бъдат на всякакъв език; за едно и също
                  упражнение трябва да се дават еднакви команди.
                  <br />
                  Когато кучето не изпълни едно цяло или част от упражнението
                  след три подадени команди от водача, то тогава същото се
                  преустановява и не се оценява. При повикване на кучето за
                  идване при водача вместо команда е разрешено извикване чрез
                  името на кучето. Команда плюс името на кучето се смята за
                  двойна команда. “Основно положение” е когато кучето седи
                  съвсем близко и успоредно от лявата страна на водача, така че
                  рамото на кучето да е до коляното на водача. Всяко
                  индивидуално упражнение започва и завършва с “основно
                  положение”.
                  <br />
                  Заемането на “основно положение” в началото на едно упражнение
                  е разрешено само един път. Къса похвала е разрешена само след
                  завършване на индивидуалното упражнение и то само в “основно
                  положение”. След това водачът има право да даде нова команда
                  за “основно положение”. Времето между похвалата и наново
                  заетото “основно положение” трябва да е ясно изразено (около
                  3сек. изчакване).
                  <br />
                  От “основно положение” започва началото на упражнението, като
                  преди да се подаде първата команда за изпълнение на всеки
                  отделен елемент, водачът трябва да извърви минимум 10 крачки,
                  но не повече от 15 крачки. Между отделните елементи: сядане на
                  кучето пред водача и завършек с “основно положение”, както и
                  при връщане на водача до седящото, стоящото или лежащо куче,
                  следващата команда се дава след кратко изчакване (около 3
                  сек.). <br />
                  Ходенето наред без повод трябва да се покаже и между
                  изпълнението на отделните индивидуални упражнения. Отпускане
                  или игра не са разрешени.
                  <br />
                  Всички “обръщания” трябва да се извършват наляво. Кучето може
                  да мине пред или зад водача. В рамките на един изпит
                  изпълнението трябва да е еднакво (пред или зад водача).
                  <br />
                  При сядане пред водача кучето може да заеме “основно
                  положение” минавайки пред или зад водача.
                  <br />
                  Перпендикулярното на земята препятствие трябва да е с висоина
                  100см. и ширина 150см.
                  <br />
                  “А”- образното препятствие се състои от две части, които са
                  свързани. Всяка една от тях е 150см. широка и 191см. висока.
                  Поставеното препятствие, измерено перпендикулярно от земята,
                  трябва да е високо 180см. Препятствието трябва да е покрито с
                  материал, който не позволява подхлъзване. На всяка една от
                  плоскостите в горната половина трябва да има три лайсни за
                  изкачване (24/48мм.).
                  <br />
                  Всички участващи кучета трябва да прескочат еднакви
                  препятствия.
                  <br />
                  При донасянето на предмет (апорт) са разрешени само така
                  наречените “апортни гири” (тежки 650гр.). “Апортната гира”,
                  която е предоставена от организатора на мероприятието, е
                  задължителна за всички участници.
                  <br />
                  Преди всяко едно от упражненията за апортиране е забранено
                  подаването на “апортната гира” в устата на кучето. Ако водачът
                  забрави някое от отделните упражнения, съдията го подканя да
                  покаже същото без да му отнема точки за това. <br />
                  1. Ходене наред без повод - 20 точки
                  <br />
                  а) Команда за ходене наред (Fuss). Командата е разрешена само
                  при трагване и при смяна на темпото.
                  <br />
                  в) Изпълнение: Водачът със свободно следващото го куче се
                  отправя към съдията, поставя кучето в седнало положение и се
                  представя. От основно положение след команда за ходене наред
                  кучето трябва да следва водача с внимание, желание и по права
                  линия, така че неговото рамо да е опряно в лявото коляно на
                  водача, като при спиране трябва да седне бързо и в права линия
                  само. От началото на упражнението водачът тръгва в права
                  линия, без да спира и с нормален ход около 50 крачки, след
                  което следва “обръщане” и още 10-15 крачки. Без спиране
                  водачът трябва да премине в бързо и след това бавно темпо,
                  като за всяко едно се изминават не по-малко от 10 крачки. При
                  смяната от бързо в бавно темпо не трябва да има прекъсване с
                  нормален ход. Скоростта на всеки един ход трябва ясно да се
                  отличава от останалите.
                  <br />
                  В нормален ход водачът трябва да покаже минимум един ляв, един
                  десен завой и едно обръщане (кръгом). Докато водачът следва
                  първата права отсечка, се дават два изстрела (калибър 6мм.) с
                  промеждутък от 5 сек. и не по-малко от 15 крачки разстояние от
                  кучето. След изпълнението на тази част от упражнението по знак
                  на съдията водачът и кучето се отправят към движеща се група
                  хора, състояща се от не по-малко от 4 човека. Водачът трябва
                  да обиколи един човек отляво, един отдясно и да спре в групата
                  най-малко веднъж, като съдията има право да поиска повторение
                  на гореспоменатото упражнение. Водачът излиза от групата и
                  заема основно положение.
                  <br />
                  с) Оценяване: Избързване, изоставане, отдалечаване от водача;
                  бавно и несигурно сядане, допълнителни команди, помощ с тяло
                  от страна на водача, недостатъчно внимание от страна на кучето
                  към водача, както и подтиснатост, се оценяват с наказателни
                  точки.
                  <br />
                  2. Сядане от движение - 10 точки
                  <br />
                  а) Команди: ходене наред (Fuss); сядане (Sitz)
                  <br />
                  в) Изпълнение: От основно положение водачът със свободно
                  следащо го куче изминава 10-15 крачки. След командата за
                  сядане (Sitz) кучето трябва да седне бързо и в посоката, в
                  която се върви, като водачът не трябва да нарушава скоростта
                  на своя ход. Кучето трябва да остане спокойно седнало, без да
                  променя позицията си и да се оглежда. След 30 крачки водачът
                  спира и се обръща с лице към кучето. По знак на съдията
                  водачът се връща при кучето си, като застава от неговата дясна
                  страна.
                  <br />
                  с) Оценяване: Изпълнение по различен начин от гореописания
                  начин, бавно сядане, неспокойно седене, както и невнимателност
                  (оглеждане) от страна на кучето се оценяват с наказателни
                  точки. Ако кучето вместо да седне, остане право или легне се
                  наказва с 5 точки.
                  <br />
                  3. Лягане от движение с отзоваване - 10 точки
                  <br />
                  а) По една команда за: ходене на ред (Fuss), лягане (Platz),
                  извикване (Hier), тръгване от основно положение (Fuss)
                  <br />
                  в) Изпълнение: От основно положение водачът и кучето тръгват в
                  права линия. След 10-15 крачки водачът дава команда за лягане
                  без да променя своето темпо и без да се оглежда. Кучето трябва
                  да легне веднага в посоката на движение. Водачът продължава
                  още около 30 крачки направо, спира и се обръща веднага към
                  своето спокойно лежащо куче. По знак на съдията водачът
                  извиква кучето си със съответната команда или чрез името на
                  кучето. Кучето трябва да дойде въодушевено, бързо и направо
                  при водача, като седне възможно най-близо в права линия пред
                  него. По команда на водача кучето трябва бързо да седне от
                  лявата му страна.
                  <br />
                  с) Оценяване: Грешки в изпълнението, бавно и неспокойно
                  лягане, бавно идване при водача, неправилна стойка на самия
                  водач (разкрачени крака), грешки при сядане при водача, както
                  и некоректно завършване на упражнението се наказват съответно
                  с отнемане на точки. Ако кучето седне или застане право след
                  командата за лягане, се наказва с 5 точки.
                  <br />
                  4. Апортиране на предмет (апортна гира) от равен терен - 10
                  точки
                  <br />
                  а) По една команда за: донасяне (Bring), пускане (Aus) и
                  основно положение (Fuss).
                  <br />
                  в) Изпълнение: От основно положение водачът хвърля апортната
                  гира (650гр.) на около 10 крачки. Командата за донасяне се
                  дава едва след като предмета е паднал и лежи неподвижно на
                  земята. По команда на водача за донасяне кучето трябва да
                  отиде бързо към предмета, да го вземе без забавяне и веднага
                  да го донесе като трябва да седне възможно най-близо и в права
                  линия пред водача. Кучето трябва да държи предмета в уста
                  спокойно, докато водачът го вземе от него след кратка пауза
                  (около 3 сек.) по команда за пускане. Апортната гира се взима
                  от водача и спокойно се държи с отпусната надолу ръка от
                  дясната страна на тялото. По команда на водача за основно
                  положение кучето трябва бързо и възможно най-близко да седне
                  от лявата му страна. От началото до края на упражнението
                  водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно отиване,
                  грешки при взимане на апортната гира, бавно връщане, изпускане
                  на предмета, игра или дъвчене на предмета се наказват с
                  отнемане на точки. Близко хвърляне на апортната гира и помощ
                  от водача без да напуска своето място се наказват по същия
                  начин (отнемане на точки). Ако водачът се отмести от
                  първоначалното си положение, упражнението се оценява с
                  “незадоволителен”. Ако кучето не донесе предмета, упражнението
                  се оценява като неизпълнено и не се присъждат точки.
                  <br />
                  5. Апортиране на предмет с преминаване на препятствие 100см. -
                  15 точки
                  <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и основно положение (Fuss)
                  <br />
                  в) Изпълнение: Кучето и водачът застават в основно положение
                  на разстояние не по-малко от 5 крачки от препятствието. От
                  основно положение водачът хвърля апортната гира (650гр.) от
                  другата страна на високото 100 см. препятствие. Командата за
                  прескок се дава едва след като предметът лежи неподвижно на
                  земята. По команда на водача прескочи и донеси (командата
                  донеси трябва да се даде по време на прескока), кучето трябва
                  бързо да прескочи препятствието, веднага да вземе предмета, да
                  прескочи отново препятствието и да седне възможно най-близо и
                  в права линия пред водача.
                  <br />
                  Разпределение на точките:
                  <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени.
                  <br />
                  Прескоци и донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало. Кучето трябва да
                  държи предмета в устата си спокойно, докато водачът го вземе
                  от него след кратка пауза (3 сек.) по команда за пускане.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението се наказват с
                  отнемане на точки. До 1 точка на прескок се отнема за
                  докосване на препятствието, а при удрянето му до 2т. Помощ от
                  водача без промяна на неговото основно положение се наказва с
                  отнемане на точки. Ако водачът напусне основното си положение
                  преди да е завършено окончателно упражнението, същото се
                  оценява като незадоволително.
                  <br />
                  6. Донасяне на предмет с преминаване на “А”-образно
                  препятствие (180см.) - 15 точки
                  <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и основно положение (Fuss)
                  <br />
                  в) Изпълнение: Водачът и кучето заемат основно положение
                  най-малко на 5 крачки пред наклонената стена . От основно
                  положение водачът хвърля апортировъчния предмет (650 гр) над
                  наклонената стена. Командата за “скок” може да се даде едва
                  когато предметът лежи на земята. Кучето, спокойно и свободно
                  седящо до водача, след команда “скок” и команда “донеси”
                  (командата за донасяне на предмета трябва да бъде подадена по
                  време на скока) трябва да премине през стената, бързо и по
                  права линия стигне до апортировъчния предмет, незабавно да го
                  вдигне, да премине обратно и бързо да донесе предмета на
                  водача. Кучето трябва да седне пред водача близо и коректно и
                  спокойно да държи предмета в уста дотогава, докато водачът
                  след пауза от 3 сек. не го вземе от него с команда “дай”.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  Разпределение на точките:
                  <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени. Прескоци и
                  донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението се наказват с
                  отнемане на точки. Помощ от водача без промяна на неговото
                  основно положение се наказва с отнемане на точки. Ако водачът
                  напусне основното си положение преди да е завършено
                  окончателно упражнението, същото се оценява като
                  незадоволително.
                  <br />
                  7. Изпращане напред с лягане - 10 точки
                  <br />
                  а) По една команда за: изпращане напред (Voraus), лягане
                  (Platz), и сядане (Sitz).
                  <br />
                  в) Изпълнение: от основно положение водачът със свободно
                  следващо го куче върви по права линия в указаната му посока.
                  След 10-15 крачки водачът, едновременно с еднократно вдигане
                  на ръка, дава на кучето команда “напред” и остава на място.
                  Кучето трябва целеустремено и бързо да тича в указаната посока
                  минимум 30 крачки. По знак на съдията водачът дава команда
                  ”легни”, след която кучето трябва незабавно да легне. Водачът
                  може да държи ръката си вдигната до момента, в който кучето
                  легне. По знак на съдията водачът отива към своето куче и
                  заема позиция в дясно от него. След около 3 сек и по знак на
                  съдията, на кучето се дава команда “седни”. Кучето трябва
                  бързо и коректно да заеме основно положение.
                  <br />
                  с) Оценяване: грешки по време на упражнението, движение на
                  водача след подадената команда, много бавно тичане напред,
                  силно отклонение в страни, много късо разстояние, бавно или
                  преждевременно лягане, неспокойно поведение по време на
                  лежането и преждевременно ставане от място довеждат до
                  отнемане на точки.
                  <br />
                  8. Престой с отвличане на вниманието - 10 точки
                  <br />
                  а) По една команда за: лягане (Platz) и сядане (Sitz).
                  <br />
                  в) Изпълнение: в началото на изпълнението на упражненията от
                  раздел “В” на друго куче, водачът поставя своето куче легнало
                  с команда ”легни” на указаното от съдията място. Кучето ляга
                  от основно положение, като при това до него не трябва да се
                  оставя повод или други предмети. Водачът, без да се обръща се
                  отдалечава от кучето минимум на 30 крачки и остава в полето на
                  неговото зрение, стоейки с гръб към него. Кучето трябва да
                  остане да лежи спокойно, без каквото и да било въздействие от
                  страна на водача, докато друго куче демонстрира упражненията
                  от 1 до 6. По знак на съдията водачът отива към своето куче и
                  заема позиция вдясно от него. След около 3 сек и по знак на
                  съдията, на кучето се подава команда “седни”. Кучето трябва
                  бързо и коректно да заеме основно положение.
                  <br />
                  с)Оценяване: неспокойно поведение на водача, а също така и
                  друга помощ, неспокойно поведение на кучето по време на
                  престоя, преждевременно ставане от място при идване на водача
                  водят до отнемане на точки. Ако кучето остане седнало или
                  право, но без да напуска мястото се дава непълна оценка. Ако
                  кучето се отдалечи от мястото на повече от 3 метра преди
                  завършване на упражнение 3 на другото куче, упражнението се
                  оценява с 0 точки. Ако кучето напусне мястото си след
                  завършване на упражнение 3 на другото куче, тогава се дава
                  непълна оценка. Ако кучето се движи срещу водача, когато той
                  идва да го вземе, се отнемат до 3 точки.
                  <br />
                  “С” - защита - 100т.
                  <br />
                  Упражнение:
                  <br />
                  1. Търсене на фигуранта - 5т.
                  <br />
                  2. Охрана и облайване - 10т.
                  <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 20т.
                  <br />
                  4. Защита след нападение от фаза на охраняване - 35т.
                  <br />
                  5. Нападение на кучето от движение - 30т.
                  <br />
                  Общо: 100 точки
                  <br />
                  Общи условия
                  <br />
                  На подходящо място са разположени по дължина 6 укрития - по 3
                  укрития на всяка страна. Необходимите означения трябва да са
                  добре видими за водача, съдията и фигуранта. Фигурантът трябва
                  да е снабден със защитен костюм, защитен ръкав и мека палка.
                  Ръкавът трябва да притежава надебеление за захапване, а
                  калъфът да е изготвен от юта с естествен цвят. Ако за
                  фигуранта е необходимо да следи кучето с поглед, не е нужно
                  той да стои неподвижно. Не е позволено обаче да извършва
                  заплашителни движения. Фигурантът трябва да покрива тялото си
                  с ръкава. Начинът на отнемане на палката на фигуранта от
                  водача се избира от водача. При изпити може да се работи с
                  един фигурант. При над 6 кучета в изпитна степен обаче са
                  нужни двама фигуранти. В рамките на една изпитна степен всички
                  водачи трябва да работят с един и същ/едни и същи фигуранти.
                  Кучета, които не могат да бъдат контролирани от водача си,
                  които не пускат или пускат само чрез голяма помощ от страна на
                  водача, които захапват други части на тялото освен определения
                  за тази цел ръкав, трябва да бъдат дисквалифицирани. Не се
                  присъжда «TSB»-оценка. При кучета, които отказват изпълнението
                  на някое упражнение от защитата или се оставят да бъдат
                  прогонени, дисциплина С трябва да бъде прекъсната. Оценяване
                  не се извършва. Дава се само «TSB»-оценка. Команда “Пусни!”
                  във всички упражнения е позволено да се подава само веднъж.
                  Оценяване на пускането (наказателни точки): колебливо пускане
                  0,5-3,0 н.т.
                  <br />
                  първа допълнителна команда с незабавно пускане 3,0 н.т.
                  <br />
                  първа допълнителна команда с колебливо пускане 3,5-6,0 н.т.
                  <br />
                  втора допълнителна команда с незабавно пускане 6,0 н.т.
                  <br />
                  втора допълнителна команда с колебливо пускане 6,5-9,0 н.т.
                  <br />
                  кучето не пуска и след втората допълнителна команда
                  дисквалификация
                  <br />
                  1. Търсене на фигуранта - 5 точки
                  <br />
                  a) Команди за: “претърсване” и “идване” (командата за идване
                  може да е свързана и с името на кучето).
                  <br />
                  в) Изпълнение: Фигурантът се намира, невидим за кучето, в
                  последното укритие. Водачът взима позиция със свободно
                  следващо го куче между четвъртото и петото укритие, така че да
                  е възможно обикалянето на две укрития. По знак на съдията се
                  дава началото на дисциплина С. След кратка команда за
                  претърсване и насочване с лявата или дясната ръка кучето
                  трябва бързо да се отдели от водача и целеустремено да се
                  насочи към петото укритие и внимателно и точно да го
                  заобиколи. Ако кучето изпълни това, водачът го извиква към
                  себе си с кратка команда за идване и с нова команда за
                  претърсване от движение го отпраща към шестото укритие, в
                  което е фигурантът. Водачът се движи с нормален ход по
                  мислената средна линия, която той не трябва да напуска по
                  време на претърсването. Кучето винаги трябва да се намира пред
                  водача. Когато кучето достигне до укритието с фигуранта,
                  водачът трябва да застане на едно място. Команди и насочване с
                  ръка вече не са позволени.
                  <br />
                  2. Охрана и облайване - 10 точки
                  <br />
                  a) Команди за: “идване” и “заставане в основно положение”.
                  <br />
                  b) Изпълнение: Кучето трябва да охранява фигуранта активно и
                  внимателно и да го облайва продължително и старателно. Кучето
                  не трябва да скача по фигуранта и да докосва ръкава. След
                  продължителност на облайването от около 20 секунди по знак на
                  съдията водачът се приближава на 5 крачки пред укритието. По
                  знак на съдията водачът подава команда на кучето си за
                  заставане в основно положение.
                  <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 20 точки
                  <br />
                  a) Команди за: “ходене на ред”, “лягане” и “пускане”
                  <br />
                  b) Изпълнение: По знак на съдията водачът принуждава фигуранта
                  да излезе от укритието. Фигурантът с нормален ход се отправя
                  към обозначената за опита за бягство изходна позиция. По знак
                  на съдията водача със свободно следващо го куче се отправя към
                  обозначената за опита за бягство позиция за лягане.
                  Разстоянието между фигурант и куче трябва да е 5 крачки.
                  Водачът подава на кучето команда за лягане и се отправя към
                  укритието. Той има зрителен контакт към кучето си, фигуранта и
                  съдията. По знак на съдията фигурантът предприема опит за
                  бягство. Кучето трябва без да се колебае самостоятелно да
                  осуети опита за бягство чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. По знак на съдията
                  фигурантът застава неподвижен на едно място, при което кучето
                  веднага трябва да пусне. Водачът в подходящо време може да
                  подаде еднократна команда за пускане. Ако кучето не пусне след
                  първата позволена команда, водачът получава знак от съдията за
                  подаване на до две допълнителни команди. Ако кучето не пусне и
                  след трите команди (една позволена и две допълнителни), следва
                  дисквалификация. По време на подаване на командите водачът
                  трябва да стои спокойно без да въздейства по друг начин върху
                  кучето. След пускането кучето трябва да остане плътно до
                  фигуранта и да го охранява внимателно.
                  <br />
                  4. Защита след нападение от фаза на охраняване - 35 точки
                  <br />
                  a) Команди за: “пускане” и “заставане в основно положение”
                  <br />
                  b) Изпълнение: След фаза на охраняване, продължаваща около 5
                  секунди, фигурантът предприема по знак на съдията нападение
                  срещу кучето. Б ез въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Ако кучето захапе,
                  му се нанасят два удара. Допускат се единствено удари по
                  раменете и областта на холката. По знак на съдията фигурантът
                  застава неподвижен на едно място, при което кучето веднага
                  трябва да пусне. Водачът в подходящо време може да подаде
                  еднократна команда за пускане.
                  <br />
                  Ако кучето не пусне след първата позволена команда, водачът
                  получава знак от съдията за подаване на до две допълнителни
                  команди. Ако кучето не пусне и след трите команди (една
                  позволена и две допълнителни), следва дисквалификация. По
                  време на подаване на командите водачът трябва да стои спокойно
                  без да въздейства по друг начин върху кучето. След пускането
                  кучето трябва да остане плътно до фигуранта и да го охранява
                  внимателно. По знак на съдията водачът в нормален ход отива до
                  кучето си и чрез команда за “заставане в основно положение” го
                  поставя в основно положение. Палката не се взима от фигуранта.
                  5. Нападение на кучето от движение - 30 точки
                  <br />
                  a) Команди за: “сядане”, “нападане”, “пускане”, “заставане в
                  основно положение” и ходене на ред”.
                  <br />
                  b) Изпълнение: Водачът се насочва със свободно следващо го
                  куче към маркирано място в средната линия на нивото на първото
                  укритие. Кучето може да бъде хванато за нашийника, но не е
                  позволено да бъде допълнително стимулирано от водача. По знак
                  на съдията от някое укритие излиза снабдения с мека палка
                  фигурант и се насочва в нормален ход към средната линия. На
                  нивото на средната линия от нормален ход фигурантът директно
                  преминава в тичане и напада фронтално кучето и водача чрез
                  прогонващи викове и застрашителни движения. Когато фигурантът
                  се приближи до кучето и водача на 30-40 крачки, по знак на
                  съдията водачът освобождава кучето си и му подава команда за
                  “нападане”. Кучето трябва да отблъсне нападението без да се
                  колебае чрез енергично и силно захапване. На кучето е
                  позволено да хапе само по ръкава. Водачът не трябва да напуска
                  указаното му място. По знак на съдията фигурантът застава
                  неподвижен на едно място, при което кучето веднага трябва да
                  пусне. Водачът в подходящо време може да подаде еднократна
                  команда за пускане.
                  <br />
                  Ако кучето не пусне след първата позволена команда, водачът
                  получава знак от съдията за подаване на до две допълнителни
                  команди. Ако кучето не пусне и след трите команди (една
                  позволена и две допълнителни), следва дисквалификация. По
                  време на подаване на командите водачът трябва да стои спокойно
                  без да въздейства по друг начин върху кучето. След пускането
                  кучето трябва да остане плътно до фигуранта и да го охранява
                  внимателно. По знак на съдията водачът в нормален ход отива до
                  кучето си и чрез команда за “заставане в основно положение” го
                  поставя в основно положение. Палката се взима от фигуранта.{" "}
                  <br />
                  Следва успореден конвой на фигуранта до съдията с
                  продължителност от около 20 крачки. Позволена е команда за
                  “заставане в основно положение”. Кучето трябва да се движи от
                  дясната страна на фигуранта, така че да се намира между
                  фигуранта и водача. По време на конвоя кучето трябва
                  внимателно да наблюдава фигуранта. Не е позволено обаче кучето
                  да се бута във фигуранта, да скача по него или да захапва
                  ръкава. Групата спира пред съдията, водачът предава палката на
                  съдията и обявява дисциплина С за приключена. Преди
                  обявяването на резултатите и по знак на съдията кучето се
                  поставя на повод. <br />
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='IPO2'>
            <Accordion.Button className='accordion-button bg-secondary'>
              IPO II
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  Дисциплини: “А”- следа - 100т.
                  <br />
                  “В” - послушание - 100т.
                  <br />
                  “С” - защита - 100т.
                  <br />
                  Общо: - 300т.
                  <br />
                  Дисциплина А - “СЛЕДА”
                  <br />
                  Чужда следа, не по-къса от 400 крачки, 3 прави отсечки, 2
                  прави ъгъла (90°), 2 предмета, и поставена не по-малко от 30
                  мин. преди старта. Време за изпълнение - 15 мин. <br />
                  Преминаване по трасето - 80 точки. <br />
                  Предмети - (10+10) - 20 точки.
                  <br />
                  Общо - 100 точки.
                  <br />
                  След оглед на предоставения терен, съдията и отговорникът по
                  трасирането определят място за полагане на следата за всеки
                  участник, като различните трасета не трябва да се пресичат или
                  да са много близко едно до друго. Съдията определя къде се
                  поставят предметите, както и посоката на ъглите (ляв или
                  десен).
                  <br />
                  Началото на следата трябва да е добре обозначено със знак,
                  който е поставен на земята вляво от нея.
                  <br />
                  Преди трасирането всеки участник показва предметите на
                  съдията, като същите не трябва да са били във водача поне от
                  30 мин.
                  <br />
                  След кратък престой на началната точка водачът преминава по
                  трасето, обозначено от съдията, с нормални крачки, без да
                  провлачва краката и без да прекъсва своя ход. По същият начин
                  се трасират и ъглите. Първият предмет се поставя след минимум
                  100 крачки на І-та или ІІ-та отсечка. Вторият предмет се
                  полага на края на следата. Предметите се полагат от движение
                  (не е разрешено спиране за поставяне на предметите). След
                  поставянето на втория предмет водачът продължава още няколко
                  крачки по права линия преди да напусне терена.
                  <br />
                  Предметите трябва да са от различен материал, като например:
                  кожа, текстил, дърво и т.н. Същите трябва да са с дължина не
                  повече от 10 см., ширина 2-3 см., дебелина 0,5-1см. и не
                  трябва да се отличават по цвят от околната среда. Всички
                  предмети трябва да бъдат номерирани, като номерът на стартовия
                  знак и номерата на предметите трябва да съвпадат. Кучето не
                  трябва да вижда трасирането на следата.
                  <br />
                  При преминаване на трасето съдията и придружаващият го не
                  трябва да разговарят, за да не пречат и разсейват водача и
                  кучето.
                  <br />
                  а) команда “Търси” (Suchen):
                  <br />
                  Командата е разрешена при започване и след обозначаването на
                  първия предмет; в) изпълнение:
                  <br />
                  Водачът подготвя кучето си за следа. Проследяването може да
                  бъде с повод с дължина 10м. или без повод. Поводът може да е
                  отгоре, отстрани или отдолу м/у предните и задните крака на
                  кучето. Може да се използва нагръдник за следа или обикновен
                  тренировъчен нашийник, но не “душач”.
                  <br />
                  След повикване от съдията водачът се представя със своето куче
                  (кучето е в основно положение) и обявява дали кучето взима
                  предметите или ги обозначава. По знак на съдията кучето се
                  отвежда бавно и спокойно към началото на трасето и започва
                  преминаването по него. Преди, в началото и през цялото време
                  на преминаване по трасето се избягва всякакво принуждаване на
                  кучето. При започване на следата кучето трябва интензивно,
                  спокойно и с нос опрян почти до земята (дълбок нос) да поеме
                  миризмата. Проследяването на трасето трябва да продължи по
                  същия начин - с нос, близко до земята, интензивно и равномерно
                  темпо. Когато кучето започне да се движи, водачът остава
                  неподвижен, докато се изпъне 10-метровият повод.
                  <br />
                  При проследяване без повод се спазва същото разстояние от 10м.
                  м/у кучето и водача. При претърсване с повод не е задължително
                  поводът да е изпънат, той може и да е леко отпуснат.
                  <br />
                  Кучето трябва да проследи и премине ъглите сигурно, след което
                  да продължи напред със същото темпо. Веднага щом кучето е
                  намерило първия предмет, трябва да го вземе или ясно да го
                  обозначи без каквато и да е помощ от водача.
                  <br />
                  При взимане на предмета кучето може да остане право, да седне
                  или да го занесе на водача. Грешно е кучето да върви напред
                  или да легне с предмета в уста.
                  <br />
                  При обозначаване на предмета кучето може да легне, да седне
                  или да е право (разрешено е всеки предмет да се обозначи по
                  различен начин). След като кучето е намерило предмета, водачът
                  пуска повода, отива към кучето и показва намерения предмет,
                  като го вдига високо във въздуха. Проследяването продължава от
                  мястото, където е намерен първият предмет. След завършване на
                  трасето намерените предмети се представят на съдията.
                  <br />
                  с) Оценяване:
                  <br />
                  Бързината, с която се проследява трасето, не е критерий за
                  оценяване, ако кучето търси интензивно, равномерно и сигурно,
                  както и когато кучето показва положително следово поведение
                  (сигурно претърсване, без да излиза от трасето).
                  <br />
                  Грешки: Системно излизане от трасето, “висок нос” (носът не е
                  близо до земята), замърсяване на трасето, обикаляне на ъглите,
                  непрекъснати подканяния, помощ чрез повода, както и вербална
                  такава от страна на водача. Неправилно взимане или
                  обозначаване на предмета. <br />
                  Ако водачът напусне трасето на разстояние повече от един
                  следови повод (10м.), претърсването се прекратява. Ако кучето
                  напусне трасето и водачът се опита да го задържи, съдията дава
                  нареждане водачът да следва кучето. Не се ли изпълни
                  нареждането на съдията, претърсването се прекратява от
                  изпитващия.
                  <br />
                  Ако до 15мин. след началото не е достигнат краят на трасето,
                  претърсването се прекратява по нареждане на съдията.
                  Извършената работа до прекратяването се оценява.
                  <br />
                  Показването на предметите чрез двата възможни начина - взимане
                  и обозначение - се счита за грешка. Оценяват се само
                  предметите, които са показани по начина, посочен от водача
                  преди започване на претърсването. За необозначени или невзети
                  предмети не се дават точки. Разделянето на точките за
                  претърсване на трасето, както и за ъглите трябва да се оценява
                  в зависимост от дължината и трудността. Оценяването на всеки
                  ъгъл поотделно се извършва чрез оценки и точки. Ако кучето не
                  търси (дълго време стои на едно място без да души), може да се
                  прекрати претърсването, дори и то да се намира на трасето.
                  <br />
                  <br />
                  Дисциплина В - “ПОСЛУШАНИЕ” <br />
                  Упражнение: <br />
                  1. Ходене наред без повод - 10т. <br />
                  2. Сядане от движение - 10т. <br />
                  3. Лягане от движение с повикване - 10т. <br />
                  4. Стоене от движение - 10т. <br />
                  5. Донасяне на предмет от равен терен - 10т. <br />
                  6. Донасяне на предмет през 1м. препятствие - 15т. <br />
                  7. Донасяне на предмет през “А”-обр. преп. - 15т. <br />
                  8. Изпращане напред с лягане - 10т. <br />
                  9. Престой - 10т. <br />
                  Общо: 100 точки <br />
                  Общи положения <br />
                  Съдията дава знак на водача да започне упражнението. Всичко
                  останало, обръщания, спиране, смяна на темпото и т.н. се
                  изпълняват без указания от страна на съдията. Разрешените
                  команди са описани във всяко упражнение поотделно. Те са
                  нормално изговорени, къси и състоящи се от една дума. Могат да
                  бъдат на всякакъв език; за едно и също упражнение трябва да се
                  дават еднакви команди. <br />
                  Когато кучето не изпълни едно цяло или част от упражнението
                  след три подадени команди от водача, то тогава същото се
                  преустановява и не се оценява. При повикване на кучето за
                  идване при водача вместо команда е разрешено извикване чрез
                  името на кучето. Команда плюс името на кучето се смята за
                  двойна команда. <br />
                  “Основно положение” е когато кучето седи съвсем близко и
                  успоредно от лявата страна на водача, така че рамото на кучето
                  да е до коляното на водача. Всяко индивидуално упражнение
                  започва и завършва с “основно положение”. <br />
                  Заемането на “основно положение” в началото на едно упражнение
                  е разрешено само един път. Къса похвала е разрешена само след
                  завършване на индивидуалното упражнение и то само в “основно
                  положение”. След това водачът има право да даде нова команда
                  за “основно положение”. <br />
                  Времето между похвалата и наново заетото “основно положение”
                  трябва да е ясно изразено (около 3сек. изчакване). <br />
                  От “основно положение” започва началото на упражнението, като
                  преди да се подаде първата команда за изпълнение на всеки
                  отделен елемент, водачът трябва да извърви минимум 10 крачки,
                  но не повече от 15 крачки. Между отделните елементи: сядане на
                  кучето пред водача и завършек с “основно положение”, както и
                  при връщане на водача до седящото, стоящото или лежащо куче,
                  следващата команда се дава след кратко изчакване (около 3
                  сек.). <br />
                  Ходенето наред без повод трябва да се покаже и между
                  изпълнението на отделните индивидуални упражнения. Отпускане
                  или игра не са разрешени. Всички “обръщания” трябва да се
                  извършват наляво. Кучето може да мине пред или зад водача. В
                  рамките на един изпит изпълнението трябва да е еднакво (пред
                  или зад водача). При сядане пред водача кучето може да заеме
                  “основно положение” минавайки пред или зад водача.
                  Перпендикулярното на земята препятствие трябва да е с височина
                  100см. и ширина 150см. <br />
                  “А”- образното препятствие се състои от две части, които са
                  свързани. Всяка една от тях е 150см. широка и 191см. висока.
                  Поставеното препятствие, измерено перпендикулярно от земята,
                  трябва да е високо 180см. Препятствието трябва да е покрито с
                  материал, който не позволява подхлъзване. На всяка една от
                  плоскостите в горната половина трябва да има три лайсни за
                  изкачване (24/48мм.). <br />
                  Всички участващи кучета трябва да прескочат еднакви
                  препятствия. <br />
                  При донасянето на предмет (апорт) са разрешени само така
                  наречените “апортни гири”, тежащи 1000гр. за равен терен и
                  650гр. за препятствията. “Апортната гира”, която е
                  предоставена от организатора на мероприятието, е задължителна
                  за всички участници. Преди всяко едно от упражненията за
                  апортиране е забранено подаването на “апортната гира” в устата
                  на кучето. Ако водачът забрави някое от отделните упражнения,
                  съдията го подканя да покаже същото без да му отнема точки за
                  това. <br />
                  1. Ходене наред без повод - 10 точки <br />
                  а) Команда за ходене наред (Fuss). Командата е разрешена само
                  при тръгване и при смяна на темпото. <br />
                  в) Изпълнение: Водачът със свободно следващо го куче се
                  отправя към съдията, поставя кучето в седнало положение и се
                  представя. От основно положение след команда за ходене наред
                  кучето трябва да следва водача с внимание, желание и по права
                  линия, така че неговото рамо да е опряно в лявото коляно на
                  водача, като при спиране трябва да седне бързо и в права линия
                  само. От началото на упражнението водачът тръгва в права
                  линия, без да спира и с нормален ход около 50 крачки, след
                  което следва “обръщане” и още 10-15 крачки. Без спиране
                  водачът трябва да премине в бързо и след това бавно темпо,
                  като за всяко едно се изминават не по-малко от 10 крачки. При
                  смяната от бързо в бавно темпо не трябва да има прекъсване с
                  нормален ход. Скоростта на всеки един ход трябва ясно да се
                  отличава от останалите. В нормален ход водачът трябва да
                  покаже минимум един ляв, един десен завой и едно обръщане
                  (кръгом). Докато водачът следва първата права отсечка, се
                  дават два изстрела (калибър 6мм.) с промеждутък от 5 сек. и не
                  по-малко от 15 крачки разстояние от кучето. След изпълнението
                  на тази част от упражнението по знак на съдията водачът и
                  кучето се отправят към движеща се група хора, състояща се от
                  не по-малко от 4 човека. Водачът трябва да обиколи един човек
                  отляво, един отдясно и да спре в групата най-малко веднъж,
                  като съдията има право да поиска повторение на гореспоменатото
                  упражнение. Водачът излиза от групата и заема основно
                  положение. <br />
                  с) Оценяване: Избързване, изоставане, отдалечаване от водача;
                  бавно и несигурно сядане, допълнителни команди, помощ с тяло
                  от страна на водача, недостатъчно внимание от страна на кучето
                  към водача, както и подтиснатост, се оценяват с наказателни
                  точки. <br />
                  2. Сядане от движение - 10 точки <br />
                  а) Команди: ходене наред (Fuss); сядане (Sitz) <br />
                  в) Изпълнение: От основно положение водачът със свободно
                  следащо го куче изминава 10-15 крачки. След командата за
                  сядане (Sitz) кучето трябва да седне бързо и в посоката, в
                  която се върви, като водачът не трябва да нарушава скоростта
                  на своя ход. Кучето трябва да остане спокойно седнало, без да
                  променя позицията си и да се оглежда. След 30 крачки водачът
                  спира и се обръща с лице към кучето. По знак на съдията
                  водачът се връща при кучето си, като застава от неговата дясна
                  страна. <br />
                  с) Оценяване: Изпълнение по различен от гореописания начин,
                  бавно сядане, неспокойно седене, както и невнимателност
                  (оглеждане) от страна на кучето, се оценяват с наказателни
                  точки. Ако кучето вместо да седне, остане право или легне, се
                  отнемат 5 точки. <br />
                  3. Лягане от движение с отзоваване - 10 точки <br />
                  а) По една команда за: ходене на ред (Fuss), лягане (Platz),
                  извикване (Hier), заставане в основно положение (Fuss) <br />
                  в) Изпълнение: От основно положение водачът и кучето тръгват в
                  права линия. След 10-15 крачки водачът дава команда за лягане
                  без да променя своето темпо и без да се оглежда. Кучето трябва
                  да легне веднага в посоката на движение. Водачът продължава
                  още около 30 крачки направо, спира и се обръща веднага към
                  своето спокойно лежащо куче. По знак на съдията водачът
                  извиква кучето си със съответната команда или чрез името на
                  кучето. Кучето трябва да дойде въодушевено, бързо и направо
                  при водача, като седне възможно най-близо в права линия пред
                  него. По команда на водача кучето трябва бързо да седне от
                  лявата му страна. <br />
                  с) Оценяване: Грешки в изпълнението, бавно и неспокойно
                  лягане, бавно идване при водача, неправилна стойка на самия
                  водач (разкрачени крака), грешки при сядане при водача, както
                  и некоректно завършване на упражнението, се наказват съответно
                  с отнемане на точки. Ако кучето седне или застане право след
                  командата за лягане, се отнемат 5 точки. <br />
                  4. Стоене от движение - 10 точки <br />
                  а) Команди: ходене наред (Fuss); заставане на място (Stehe);
                  сядане (Sitz) <br />
                  в) Изпълнение: От основно положение водачът със свободно
                  следащо го куче изминава 10-15 крачки. След командата за
                  заставане (Stehe) кучето трябва да застане бързо на едно място
                  и в посоката, в която се върви, като водачът не трябва да
                  нарушава скоростта на своя ход. Кучето трябва да остане
                  спокойно право, без да променя позицията си и да се оглежда.
                  След 30 крачки водачът спира и се обръща с лице към кучето. По
                  знак на съдията водачът се връща при кучето си, като застава
                  от неговата дясна страна. След около 3сек. кучето по знак на
                  съдията трябва да седне бързо и коректно след подадена команда
                  за сядане. <br />
                  с) Оценяване: Изпълнение по различен от гореописания начин,
                  пристъпване след командата, неспокойно стоене, неспокойно
                  поведение при връщането на водача, бавно сядане в края на
                  упражнението, се оценяват с наказателни точки. Ако кучето лежи
                  или седи след командата за заставане (Stehe), се отнемат 5
                  точки. <br />
                  5. Апортиране на предмет (апортна гира) от равен терен - 10
                  точки <br />
                  а) По една команда за: донасяне (Bring), пускане (Aus) и
                  заставане в основно положение (Fuss). <br />
                  в) Изпълнение: От основно положение водачът хвърля апортната
                  гира (1000гр.) на около 10 крачки. Командата за донасяне се
                  дава едва след като предметът е паднал и лежи неподвижно на
                  земята. По команда на водача за донасяне кучето трябва да
                  отиде бързо към предмета, да го вземе без забавяне и веднага
                  да го донесе, като трябва да седне възможно най-близо и в
                  права линия пред водача. Кучето трябва да държи предмета в
                  уста спокойно, докато водачът го вземе от него след кратка
                  пауза (около 3 сек.) по команда за пускане. Апортната гира се
                  взима от водача и спокойно се държи с отпусната надолу ръка от
                  дясната страна на тялото. По команда на водача за основно
                  положение кучето трябва бързо и възможно най-близко да седне
                  от лявата му страна. От началото до края на упражнението
                  водачът трябва да стои в основно положение. <br />
                  с) Оценяване: Грешки в основното положение, бавно отиване,
                  грешки при взимане на апортната гира, бавно връщане, изпускане
                  на предмета, игра или дъвчене на предмета, се наказват с
                  отнемане на точки. Близко хвърляне на апортната гира и помощ
                  от водача без да напуска своето място се наказват по същия
                  начин (отнемане на точки). Ако водачът се отмести от
                  първоначалното си положение, упражнението се оценява с
                  “незадоволителен”. Ако кучето не донесе предмета, упражнението
                  се оценява като неизпълнено и не се присъждат точки. <br />
                  6. Апортиране на предмет с преминаване на препятствие 100см. -
                  15 точки <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и заставане в основно положение (Fuss) <br />
                  в) Изпълнение: Кучето и водачът застават в основно положение
                  на разстояние не по-малко от 5 крачки от препятствието. От
                  основно положение водачът хвърля апортната гира (650гр.) от
                  другата страна на високото 100 см. препятствие. Командата за
                  прескок се дава едва след като предметът лежи неподвижно на
                  земята. По команда на водача прескочи и донеси (командата
                  донеси трябва да се даде по време на прескока), кучето трябва
                  бързо да прескочи препятствието, веднага да вземе предмета, да
                  прескочи отново препятствието и да седне възможно най-близо и
                  в права линия пред водача. <br />
                  Разпределение на точките: <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени.
                  <br />
                  Прескоци и донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало. Кучето трябва да
                  държи предмета в устата си спокойно, докато водачът го вземе
                  от него след кратка пауза (3 сек.) по команда за пускане.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна.
                  <br />
                  От началото до края на упражнението водачът трябва да стои в
                  основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението, се наказват с
                  отнемане на точки. До 1 точка на прескок се отнема за
                  докосване на препятствието, а при удрянето му до 2т. Помощ от
                  водача без промяна на неговото основно положение се наказва с
                  отнемане на точки. Ако водачът напусне основното си положение
                  преди да е завършено окончателно упражнението, същото се
                  оценява като незадоволително.
                  <br />
                  7. Донасяне на предмет с преминаване на “А”-образно
                  препятствие (180см.) - 15 точки
                  <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и заставане в основно положение (Fuss)
                  <br />
                  в) Изпълнение: Водачът и кучето заемат основно положение
                  най-малко на 5 крачки пред наклонената стена . От основно
                  положение водачът хвърля апортировъчния предмет (650 гр) над
                  наклонената стена. Командата за “скок” може да се даде едва
                  когато предметът лежи на земята. Кучето, спокойно и свободно
                  седящо до водача, след команда “скок” и команда “донеси”
                  (командата за донасяне на предмета трябва да бъде подадена по
                  време на скока) трябва да премине през стената, бързо и по
                  права линия стигне до апортировъчния предмет, незабавно да го
                  вдигне, да премине обратно и бързо да донесе предмета на
                  водача. Кучето трябва да седне пред водача близо и коректно и
                  спокойно да държи предмета в уста дотогава, докато водачът
                  след пауза от 3 сек. не го вземе от него с команда “дай”.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  Разпределение на точките:
                  <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени.
                  <br />
                  Прескоци и донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало. Кучето трябва да
                  държи предмета в устата си спокойно, докато водачът го вземе
                  от него след кратка пауза (3 сек.) по команда за пускане.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението, се наказват с
                  отнемане на точки. Помощ от водача без промяна на неговото
                  основно положение се наказва с отнемане на точки. Ако водачът
                  напусне основното си положение преди да е завършено
                  окончателно упражнението, същото се оценява като
                  незадоволително.
                  <br />
                  8. Изпращане напред с лягане - 10 точки
                  <br />
                  а) По една команда за: изпращане напред (Voraus), лягане
                  (Platz), и сядане (Sitz).
                  <br />
                  в) Изпълнение: от основно положение водачът със свободно
                  следващо го куче върви по права линия в указаната му посока.
                  След 10-15 крачки водачът, едновременно с еднократно вдигане
                  на ръка, дава на кучето команда “напред” и остава на място.
                  Кучето трябва целеустремено и бързо да тича в указаната посока
                  минимум 30 крачки. По знак на съдията водачът дава команда
                  ”легни”, след която кучето трябва незабавно да легне. Водачът
                  може да държи ръката си вдигната до момента, в който кучето
                  легне. По знак на съдията водачът отива към своето куче и
                  заема позиция в дясно от него. След около 3 сек и по знак на
                  съдията, на кучето се дава команда “седни”. Кучето трябва
                  бързо и коректно да заеме основно положение.
                  <br />
                  с) Оценяване: грешки по време на упражнението, движение на
                  водача след подадената команда, много бавно тичане напред,
                  силно отклонение в страни, много късо разстояние, бавно или
                  преждевременно лягане, неспокойно поведение по време на
                  лежането и преждевременно ставане от място довеждат до
                  отнемане на точки.
                  <br />
                  9. Престой с отвличане на вниманието - 10 точки
                  <br />
                  а) По една команда за: лягане (Platz) и сядане (Sitz).
                  <br />
                  в) Изпълнение: в началото на изпълнението на упражненията от
                  раздел “В” на друго куче, водачът поставя своето куче легнало
                  с команда ”легни” на указаното от съдията място. Кучето ляга
                  от основно положение, като при това до него не трябва да се
                  оставя повод или други предмети. Водачът, без да се обръща, се
                  отдалечава от кучето минимум на 30 крачки и остава в полето на
                  неговото зрение, стоейки с гръб към него. Кучето трябва да
                  остане да лежи спокойно, без каквото и да било въздействие от
                  страна на водача, докато друго куче демонстрира упражненията
                  от 1 до 7. По знак на съдията водачът отива към своето куче и
                  заема позиция вдясно от него. След около 3 сек и по знак на
                  съдията, на кучето се подава команда “седни”. Кучето трябва
                  бързо и коректно да заеме основно положение.
                  <br />
                  с) Оценяване: неспокойно поведение на водача, а също така и
                  друга помощ, неспокойно поведение на кучето по време на
                  престоя, преждевременно ставане от място при идване на водача,
                  водят до отнемане на точки. Ако кучето остане седнало или
                  право, но без да напуска мястото, се дава непълна оценка. Ако
                  кучето се отдалечи от мястото на повече от 3 метра преди
                  завършване на упражнение 4 на другото куче, упражнението се
                  оценява с 0 точки. Ако кучето напусне мястото си след
                  завършване на упражнение 4 на другото куче, тогава се дава
                  непълна оценка. Ако кучето се движи срещу водача, когато той
                  идва да го вземе, се отнемат до 3 точки.
                  <br />
                  Дисциплина С - "ЗАЩИТА"
                  <br />
                  Упражнение:
                  <br />
                  1. Търсене на фигуранта - 5т.
                  <br />
                  2. Охрана и облайване - 10т.
                  <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 10т.
                  <br />
                  4. Защита след нападение от фаза на охраняване - 20т.
                  <br />
                  5. Конвой - 5т.
                  <br />
                  6. Нападание на кучето от конвоя - 30т.
                  <br />
                  7. Нападение на кучето от движение - 20т.
                  <br />
                  Общо: 100 точки
                  <br />
                  Общи условия
                  <br />
                  На подходящо място са разположени по дължина 6 укрития - по 3
                  укрития на всяка страна. Необходимите означения трябва да са
                  добре видими за водача, съдията и фигуранта. Фигурантът трябва
                  да е снабден със защитен костюм, защитен ръкав и мека палка.
                  Ръкавът трябва да притежава надебеление за захапване, а
                  калъфът да е изготвен от юта с естествен цвят. Ако за
                  фигуранта е необходимо да следи кучето с поглед, не е нужно
                  той да стои неподвижно. Не е позволено обаче да извършва
                  заплашителни движения. Фигурантът трябва да покрива тялото си
                  с ръкава. Начинът на отнемане на палката на фигуранта от
                  водача се избира от водача. При изпити може да се работи с
                  един фигурант. При над 6 кучета в изпитна степен обаче са
                  нужни двама фигуранти. В рамките на една изпитна степен всички
                  водачи трябва да работят с един и същ/едни и същи фигуранти.
                  Кучета, които не могат да бъдат контролирани от водача си,
                  които не пускат или пускат само чрез голяма помощ от страна на
                  водача, които захапват други части на тялото освен определения
                  за тази цел ръкав, трябва да бъдат дисквалифицирани. Не се
                  присъжда «TSB»-оценка.
                  <br />
                  При кучета, които отказват изпълнението на някое упражнение от
                  защитата или се оставят да бъдат прогонени, дисциплина С
                  трябва да бъде прекъсната. Оценяване не се извършва. Дава се
                  само «TSB»-оценка. Команда “Пусни!” във всички упражнения е
                  позволено да се подава само веднъж. <br />
                  Оценяване на пускането (наказателни точки):
                  <br />
                  колебливо пускане 0,5-3,0 н.т.
                  <br />
                  първа допълнителна команда с незабавно пускане 3,0 н.т.
                  <br />
                  първа допълнителна команда с колебливо пускане 3,5-6,0 н.т.
                  <br />
                  втора допълнителна команда с незабавно пускане 6,0 н.т.
                  <br />
                  втора допълнителна команда с колебливо пускане 6,5-9,0 н.т.
                  <br />
                  кучето не пуска и след втората допълнителна команда
                  дисквалификация
                  <br />
                  1. Търсене на фигуранта - 5 точки
                  <br />
                  a) Команди за: “претърсване” и “идване” (командата за идване
                  може да е свързана и с името на кучето).
                  <br />
                  в) Изпълнение: Фигурантът се намира, невидим за кучето, в
                  последното укритие. Водачът взима позиция със свободно
                  следващо го куче между второто и третото укритие, така че да е
                  възможно обикалянето на четири укрития. По знак на съдията се
                  дава началото на дисциплина С. След кратка команда за
                  претърсване и насочване с лявата или дясната ръка кучето
                  трябва бързо да се отдели от водача и целеустремено да се
                  насочи към посоченото укритие и внимателно и точно да го
                  заобиколи. Ако кучето изпълни това, водачът го извиква към
                  себе си с кратка команда за идване и с нова команда за
                  претърсване от движение го отпраща към следващото укритие.
                  Водачът се движи с нормален ход по мислената средна линия,
                  която той не трябва да напуска по време на претърсването.
                  Кучето винаги трябва да се намира пред водача. Когато кучето
                  достигне до укритието с фигуранта, водачът трябва да застане
                  на едно място. Команди и насочване с ръка вече не са
                  позволени.
                  <br />
                  2. Охрана и облайване - 10 точки <br />
                  a) Команди за: “идване” и “заставане в основно положение”.
                  <br />
                  b) Изпълнение: Кучето трябва да охранява фигуранта активно и
                  внимателно и да го облайва продължително и старателно. Кучето
                  не трябва да скача по фигуранта и да докосва ръкава. След
                  продължителност на облайването от около 20 секунди по знак на
                  съдията водачът се приближава на 5 крачки пред укритието. По
                  знак на съдията водачът подава команда на кучето си за
                  заставане в основно положение.
                  <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 10 точки
                  <br />
                  a) Команди за: “ходене на ред”, “лягане” и “пускане”
                  <br />
                  b) Изпълнение: По знак на съдията водачът принуждава фигуранта
                  да излезе от укритието. Фигурантът с нормален ход се отправя
                  към обозначената за опита за бягство изходна позиция. По знак
                  на съдията водача със свободно следващо го куче се отправя към
                  обозначената за опита за бягство позиция за лягане.
                  Разстоянието между фигуранта и кучето трябва да е 5 крачки.
                  Водачът подава на кучето команда за лягане и се отправя към
                  укритието. Той има зрителен контакт към кучето си, фигуранта и
                  съдията. По знак на съдията фигурантът предприема опит за
                  бягство. Кучето трябва без да се колебае самостоятелно да
                  осуети опита за бягство чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. По знак на съдията
                  фигурантът застава неподвижен на едно място, при което кучето
                  веднага трябва да пусне. Водачът в подходящо време може да
                  подаде еднократна команда за пускане. Ако кучето не пусне след
                  първата позволена команда, водачът получава знак от съдията за
                  подаване на до две допълнителни команди. Ако кучето не пусне и
                  след трите команди (една позволена и две допълнителни), следва
                  дисквалификация. По време на подаване на командите водачът
                  трябва да стои спокойно без да въздейства по друг начин върху
                  кучето. След пускането кучето трябва да остане плътно до
                  фигуранта и да го охранява внимателно.
                  <br />
                  4. Защита след нападение от фаза на охраняване - 20 точки
                  <br />
                  a) Команди за: “пускане” и “заставане в основно положение”
                  <br />
                  b) Изпълнение: След фаза на охраняване, продължаваща около 5
                  секунди, фигурантът предприема по знак на съдията нападение
                  срещу кучето. Без въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Ако кучето захапе,
                  му се нанасят два удара. Допускат се единствено удари по
                  раменете и областта на холката. По знак на съдията фигурантът
                  застава неподвижен на едно място, при което кучето веднага
                  трябва да пусне. Водачът в подходящо време може да подаде
                  еднократна команда за пускане. Ако кучето не пусне след
                  първата позволена команда, водачът получава знак от съдията за
                  подаване на до две допълнителни команди. Ако кучето не пусне и
                  след трите команди (една позволена и две допълнителни), следва
                  дисквалификация. По време на подаване на командите водачът
                  трябва да стои спокойно без да въздейства по друг начин върху
                  кучето. След пускането кучето трябва да остане плътно до
                  фигуранта и да го охранява внимателно. По знак на съдията
                  водачът в нормален ход отива до кучето си и чрез команда за
                  “заставане в основно положение” го поставя в основно
                  положение. Палката не се взима от фигуранта.
                  <br />
                  5. Конвой - 5 точки
                  <br />
                  a) Команди за: “ходене на ред”.
                  <br />
                  в) Изпълнение: След упражнение 4 следва конвой на фигуранта с
                  продължителност от около 30 крачки. Протичането на конвоя се
                  определя от съдията. Водачът принуждава фигуранта да върви
                  напред и тръгва със свободно следващо го на ред и внимателно
                  наблюдаващо фигуранта куче на дистанция от 5 крачки зад
                  фигуранта. Дистанцията от 5 крачки трябва да се спазва по
                  време на целия конвой.
                  <br />
                  6. Нападание на кучето от конвоя - 30 точки
                  <br />
                  a) Команди за: “пускане” и “ходене на ред”.
                  <br />
                  в) Изпълнение: От конвоя по знак на съдията следва нападение
                  срещу кучето. Без въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Когато кучето
                  захапе, водачът трябва веднага да застане на едно място. По
                  знак на съдията фигурантът прекратява нападението, след което
                  кучето незабавно трябва да пусне. Водачът в подходящо време
                  може да подаде еднократна команда за пускане. Ако кучето не
                  пусне след първата позволена команда, водачът получава знак от
                  съдията за подаване на до две допълнителни команди. Ако кучето
                  не пусне и след трите команди (една позволена и две
                  допълнителни), следва дисквалификация. По време на подаване на
                  командите водачът трябва да стои спокойно без да въздейства по
                  друг начин върху кучето. След пускането кучето трябва да
                  остане плътно до фигуранта и да го охранява внимателно. По
                  знак на съдията водачът в нормален ход отива до кучето си и
                  чрез команда за “заставане в основно положение” го поставя в
                  основно положение. Палката се взима от фигуранта.
                  <br />
                  Следва успореден конвой на фигуранта до съдията с
                  продължителност от около 20 крачки. Позволена е една команда
                  за "ходене на ред". Кучето трябва да върви отдясно на
                  фигуранта, така че да се намира между водача и фигуранта. По
                  време на конвоя кучето трябва внимателно да наблюдава
                  фигуранта. Не е позволено обаче кучето да се бута във
                  фигуранта, да скача по него или да захапва ръкава. Групата
                  спира пред съдията, водачът предава палката на съдията и
                  обявява първата част на дисциплина С за приключена.
                  <br />
                  7. Нападение на кучето от движение - 20 точки <br />
                  a) Команди за: “сядане”, “нападане”, “пускане”, “заставане в
                  основно положение” и ходене на ред”.
                  <br />
                  b) Изпълнение: Водачът се насочва със свободно следващо го
                  куче към маркираното място в средната линия на нивото на
                  първото укритие. Кучето може да бъде хванато за нашийника, но
                  не е позволено да бъде допълнително стимулирано от водача. По
                  знак на съдията от някое укритие излиза снабдения с мека палка
                  фигурант и се насочва тичайки към средната линия. На нивото на
                  средната линия фигурантът без да намалява темпото си се обръща
                  към водача и напада фронтално кучето и водача чрез прогонващи
                  викове и застрашителни движения. Когато фигурантът се приближи
                  до кучето и водача на 40-50 крачки, по знак на съдията водачът
                  освобождава кучето си и му подава команда за “нападане”.
                  Кучето трябва да отблъсне нападението без да се колебае чрез
                  енергично и силно захапване. На кучето е позволено да хапе
                  само по ръкава. Водачът не трябва да напуска указаното му
                  място. По знак на съдията фигурантът застава неподвижен на
                  едно място, при което кучето веднага трябва да пусне. Водачът
                  в подходящо време може да подаде еднократна команда за
                  пускане.
                  <br />
                  Ако кучето не пусне след първата позволена команда, водачът
                  получава знак от съдията за подаване на до две допълнителни
                  команди. Ако кучето не пусне и след трите команди (една
                  позволена и две допълнителни), следва дисквалификация. По
                  време на подаване на командите водачът трябва да стои спокойно
                  без да въздейства по друг начин върху кучето. След пускането
                  кучето трябва да остане плътно до фигуранта и да го охранява
                  внимателно. По знак на съдията водачът в нормален ход отива до
                  кучето си и чрез команда за “заставане в основно положение” го
                  поставя в основно положение. Палката се взима от фигуранта.
                  <br />
                  Следва успореден конвой на фигуранта до съдията с
                  продължителност от около 20 крачки. Позволена е една команда
                  за "ходене на ред". Кучето трябва да върви отдясно на
                  фигуранта, така че да се намира между водача и фигуранта. По
                  време на конвоя кучето трябва внимателно да наблюдава
                  фигуранта. Не е позволено обаче кучето да се бута във
                  фигуранта, да скача по него или да захапва ръкава. Групата
                  спира пред съдията, водачът предава палката на съдията и
                  обявява дисциплина С за приключена. Преди обявяването на
                  резултатите и по знак на съдията кучето се поставя на повод.{" "}
                  <br />
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='IPO3'>
            <Accordion.Button className='accordion-button bg-secondary'>
              IPO III
            </Accordion.Button>
            <Accordion.Body>
              <Card className='p-1 border-0' variant='light'>
                <Card.Text>
                  Дисциплини: <br />
                  “А”- следа - 100т. <br />
                  “В” - послушание - 100т. <br />
                  “С” - защита - 100т. <br />
                  Общо: - 300т. <br />
                  Дисциплина А - “СЛЕДА”
                  <br />
                  Чужда следа, не по-къса от 600 крачки, <br />
                  5 прави отсечки, 4 прави ъгъла (90°), <br />
                  3 предмета, и поставена не по-малко от 60 мин. преди старта.
                  Време за изпълнение - 20 мин.
                  <br />
                  Преминаване по трасето - 80 точки.
                  <br />
                  Предмети - (7+7+6) - 20 точки.
                  <br />
                  Общо - 100 точки.
                  <br />
                  След оглед на предоставения терен, съдията и отговорникът по
                  трасирането определят място за полагане на следата за всеки
                  участник, като различните трасета не трябва да се пресичат или
                  да са много близко едно до друго. Съдията определя къде се
                  поставят предметите, както и посоката на ъглите (ляв или
                  десен).
                  <br />
                  Началото на следата трябва да е добре обозначено със знак,
                  който е поставен на земята вляво от нея.
                  <br />
                  Преди трасирането всеки участник показва предметите на
                  съдията, като същите не трябва да са били във водача поне от
                  30 мин.
                  <br />
                  След кратък престой на началната точка водачът преминава по
                  трасето, обозначено от съдията, с нормални крачки, без да
                  провлачва краката и без да прекъсва своя ход. По същият начин
                  се трасират и ъглите. Първият предмет се поставя след минимум
                  100 крачки на І-та или ІІ-та отсечка. Вторият предмет се
                  поставя на II-та или III-та отсечка. Третият предмет се полага
                  на края на следата. Предметите се полагат от движение (не е
                  разрешено спиране за поставяне на предметите). След
                  поставянето на последния предмет водачът продължава още
                  няколко крачки по права линия преди да напусне терена.
                  Предметите трябва да са от различен материал, като например:
                  кожа, текстил, дърво и т.н. Същите трябва да са с дължина не
                  повече от 10 см., ширина 2-3 см., дебелина 0,5-1см. и не
                  трябва да се отличават по цвят от околната среда. Всички
                  предмети трябва да бъдат номерирани, като номерът на стартовия
                  знак и номерата на предметите трябва да съвпадат. Кучето не
                  трябва да вижда трасирането на следата. При преминаване на
                  трасето съдията и придружаващият го не трябва да разговарят,
                  за да не пречат и разсейват водача и кучето.
                  <br />
                  а) команда “Търси” (Suchen):
                  <br />
                  Командата е разрешена при започване и след обозначаването на
                  първия и втория предмет;
                  <br />
                  в) изпълнение:
                  <br />
                  Водачът подготвя кучето си за следа. Проследяването може да
                  бъде с повод с дължина 10м. или без повод. Поводът може да е
                  отгоре, отстрани или отдолу м/у предните и задните крака на
                  кучето. Може да се използва нагръдник за следа или обикновен
                  тренировъчен нашийник, но не “душач”. След повикване от
                  съдията водачът се представя със своето куче (кучето е в
                  основно положение) и обявява дали кучето взима предметите или
                  ги обозначава. По знак на съдията кучето се отвежда бавно и
                  спокойно към началото на трасето и започва преминаването по
                  него. Преди, в началото и през цялото време на преминаване по
                  трасето се избягва всякакво принуждаване на кучето. При
                  започване на следата кучето трябва интензивно, спокойно и с
                  нос опрян почти до земята (дълбок нос) да поеме миризмата.
                  Проследяването на трасето трябва да продължи по същият начин -
                  с нос, близко до земята, интензивно и равномерно темпо. Когато
                  кучето започне да се движи, водачът остава неподвижен, докато
                  се изпъне 10-метровият повод. При проследяване без повод се
                  спазва същото разстояние от 10м. м/у кучето и водача. При
                  претърсване с повод не е задължително поводът да е изпънат,
                  той може и да е леко отпуснат. Кучето трябва да проследи и
                  премине ъглите сигурно, след което да продължи напред със
                  същото темпо. Веднага щом кучето е намерило предмет, трябва да
                  го вземе или ясно да го обозначи без каквато и да е помощ от
                  водача. При взимане на предмета кучето може да остане право,
                  да седне или да го занесе на водача. Грешно е кучето да върви
                  напред или да легне с предмета в уста. При обозначаване на
                  предмета кучето може да легне, да седне или да е право
                  (разрешено е всеки предмет да се обозначи по различен начин).
                  След като кучето е намерило предмета, водачът пуска повода,
                  отива към кучето и показва намерения предмет, като го вдига
                  високо във въздуха. Проследяването продължава от мястото,
                  където е намерен първият предмет. След завършване на трасето
                  намерените предмети се представят на съдията.
                  <br />
                  с) Оценяване:
                  <br />
                  Бързината, с която се проследява трасето, не е критерий за
                  оценяване, ако кучето търси интензивно, равномерно и сигурно,
                  както и когато кучето показва положително следово поведение
                  (сигурно претърсване, без да излиза от трасето).
                  <br />
                  <br />
                  Дисциплина В - “ПОСЛУШАНИЕ”
                  <br />
                  Упражнение:
                  <br />
                  1. Ходене наред без повод - 10т.
                  <br />
                  2. Сядане от движение - 10т.
                  <br />
                  3. Лягане от движение с повикване - 10т.
                  <br />
                  4. Стоене от тичане - 10т.
                  <br />
                  5. Донасяне на предмет от равен терен - 10т.
                  <br />
                  6. Донасяне на предмет през 1м. препятствие - 15т.
                  <br />
                  7. Донасяне на предмет през “А”-обр. преп. - 15т.
                  <br />
                  8. Изпращане напред с лягане - 10т.
                  <br />
                  9. Престой - 10т.
                  <br />
                  Общо: 100 точки
                  <br />
                  Общи положения
                  <br />
                  Съдията дава знак на водача да започне упражнението. Всичко
                  останало, обръщания, спиране, смяна на темпото и т.н. се
                  изпълняват без указания от страна на съдията.
                  <br />
                  Разрешените команди са описани във всяко упражнение поотделно.
                  Те са нормално изговорени, къси и състоящи се от една дума.
                  Могат да бъдат на всякакъв език; за едно и също упражнение
                  трябва да се дават еднакви команди.
                  <br />
                  Когато кучето не изпълни едно цяло или част от упражнението
                  след три подадени команди от водача, то тогава същото се
                  преустановява и не се оценява. При повикване на кучето за
                  идване при водача вместо команда е разрешено извикване чрез
                  името на кучето. Команда плюс името на кучето се смята за
                  двойна команда.
                  <br />
                  “Основно положение” е когато кучето седи съвсем близко и
                  успоредно от лявата страна на водача, така че рамото на кучето
                  да е до коляното на водача. Всяко индивидуално упражнение
                  започва и завършва с “основно положение”.
                  <br />
                  Заемането на “основно положение” в началото на едно упражнение
                  е разрешено само един път. Къса похвала е разрешена само след
                  завършване на индивидуалното упражнение и то само в “основно
                  положение”. След това водачът има право да даде нова команда
                  за “основно положение”. Времето между похвалата и наново
                  заетото “основно положение” трябва да е ясно изразено (около
                  3сек. изчакване).
                  <br />
                  От “основно положение” започва началото на упражнението, като
                  преди да се подаде първата команда за изпълнение на всеки
                  отделен елемент, водачът трябва да извърви минимум 10 крачки,
                  но не повече от 15 крачки. Между отделните елементи: сядане на
                  кучето пред водача и завършек с “основно положение”, както и
                  при връщане на водача до седящото, стоящото или лежащо куче,
                  следващата команда се дава след кратко изчакване (около 3
                  сек.).
                  <br />
                  Ходенето наред без повод трябва да се покаже и между
                  изпълнението на отделните индивидуални упражнения. Отпускане
                  или игра не са разрешени.
                  <br />
                  Всички “обръщания” трябва да се извършват наляво. Кучето може
                  да мине пред или зад водача. В рамките на един изпит
                  изпълнението трябва да е еднакво (пред или зад водача). При
                  сядане пред водача кучето може да заеме “основно положение”
                  минавайки пред или зад водача. Перпендикулярното на земята
                  препятствие трябва да е с висоина 100см. и ширина 150см.
                  <br />
                  “А”- образното препятствие се състои от две части, които са
                  свързани. Всяка една от тях е 150см. широка и 191см. висока.
                  Поставеното препятствие, измерено перпендикулярно от земята,
                  трябва да е високо 180см. Препятствието трябва да е покрито с
                  материал, който не позволява подхлъзване. На всяка една от
                  плоскостите в горната половина трябва да има три лайсни за
                  изкачване (24/48мм.).
                  <br />
                  Всички участващи кучета трябва да прескочат еднакви
                  препятствия. При донасянето на предмет (апорт) са разрешени
                  само така наречените “апортни гири”, тежащи 2000гр. з а равен
                  терен и 650гр. за препятствията. “Апортната гира”, която е
                  предоставена от организатора на мероприятието, е задължителна
                  за всички участници. Преди всяко едно от упражненията за
                  апортиране е забранено подаването на “апортната гира” в устата
                  на кучето.
                  <br />
                  Ако водачът забрави някое от отделните упражнения, съдията го
                  подканя да покаже същото без да му отнема точки за това.
                  <br />
                  1. Ходене наред без повод - 10 точки
                  <br />
                  а) Команда за ходене наред (Fuss). Командата е разрешена само
                  при тръгване и при смяна на темпото.
                  <br />
                  в) Изпълнение: Водачът със свободно следващо го куче се
                  отправя към съдията, поставя кучето в седнало положение и се
                  представя. От основно положение след команда за ходене наред
                  кучето трябва да следва водача с внимание, желание и по права
                  линия, така че неговото рамо да е опряно в лявото коляно на
                  водача, като при спиране трябва да седне бързо и в права линия
                  само. От началото на упражнението водачът тръгва в права
                  линия, без да спира и с нормален ход около 50 крачки, след
                  което следва “обръщане” и още 10-15 крачки. Без спиране
                  водачът трябва да премине в бързо и след това бавно темпо,
                  като за всяко едно се изминават не по-малко от 10 крачки. При
                  смяната от бързо в бавно темпо не трябва да има прекъсване с
                  нормален ход. Скоростта на всеки един ход трябва ясно да се
                  отличава от останалите. В нормален ход водачът трябва да
                  покаже минимум един ляв, един десен завой и едно обръщане
                  (кръгом). Докато водачът следва първата права отсечка, се
                  дават два изстрела (калибър 6мм.) с промеждутък от 5 сек. и не
                  по-малко от 15 крачки разстояние от кучето. След изпълнението
                  на тази част от упражнението по знак на съдията водачът и
                  кучето се отправят към движеща се група хора, състояща се от
                  не по-малко от 4 човека. Водачът трябва да обиколи един човек
                  отляво, един отдясно и да спре в групата най-малко веднъж,
                  като съдията има право да поиска повторение на гореспоменатото
                  упражнение. Водачът излиза от групата и заема основно
                  положение.
                  <br />
                  с) Оценяване: Избързване, изоставане, отдалечаване от водача;
                  бавно и несигурно сядане, допълнителни команди, помощ с тяло
                  от страна на водача, недостатъчно внимание от страна на кучето
                  към водача, както и подтиснатост, се оценяват с наказателни
                  точки.
                  <br />
                  2. Сядане от движение - 10 точки
                  <br />
                  а) Команди: ходене наред (Fuss); сядане (Sitz)
                  <br />
                  в) Изпълнение: От основно положение водачът със свободно
                  следащо го куче изминава 10-15 крачки. След командата за
                  сядане (Sitz) кучето трябва да седне бързо и в посоката, в
                  която се върви, като водачът не трябва да нарушава скоростта
                  на своя ход. Кучето трябва да остане спокойно седнало, без да
                  променя позицията си и да се оглежда. След 30 крачки водачът
                  спира и се обръща с лице към кучето. По знак на съдията
                  водачът се връща при кучето си, като застава от неговата дясна
                  страна.
                  <br />
                  с) Оценяване: Изпълнение по различен от гореописания начин,
                  бавно сядане, неспокойно седене, както и невнимателност
                  (оглеждане) от страна на кучето, се оценяват с наказателни
                  точки. Ако кучето вместо да седне, остане право или легне, се
                  отнемат 5 точки.
                  <br />
                  3. Лягане от движение с отзоваване - 10 точки
                  <br />
                  а) По една команда за: ходене на ред (Fuss), лягане (Platz),
                  извикване (Hier), заставане в основно положение (Fuss)
                  <br />
                  в) Изпълнение: От основно положение водачът и кучето тръгват в
                  права линия. След 10-15 крачки нормален ход следват 10-15
                  крачки тичане. След това водачът дава команда за лягане без да
                  променя своето темпо и без да се оглежда. Кучето трябва да
                  легне веднага в посоката на движение. Водачът продължава още
                  около 30 крачки направо, спира и се обръща веднага към своето
                  спокойно лежащо куче. По знак на съдията водачът извиква
                  кучето си със съответната команда или чрез името на кучето.
                  Кучето трябва да дойде въодушевено, бързо и направо при
                  водача, като седне възможно най-близо в права линия пред него.
                  По команда на водача кучето трябва бързо да седне от лявата му
                  страна.
                  <br />
                  с) Оценяване: Грешки в изпълнението, бавно и неспокойно
                  лягане, бавно идване при водача, неправилна стойка на самия
                  водач (разкрачени крака), грешки при сядане при водача, както
                  и некоректно завършване на упражнението, се наказват съответно
                  с отнемане на точки. Ако кучето седне или застане право след
                  командата за лягане, се отнемат 5 точки.
                  <br />
                  4. Стоене от тичане - 10 точки
                  <br />
                  а) По една команда за: ходене на ред (Fuss), заставане на
                  място (Stehe), извикване (Hier), заставане в основно положение
                  (Fuss)
                  <br />
                  в) Изпълнение: От основно положение водачът със свободно
                  следващо го куче изтичва 10-15 крачки. След командата за
                  заставане (Stehe) кучето трябва да застане бързо на едно място
                  и в посоката, в която се върви, като водачът не трябва да
                  нарушава скоростта на своя ход. Кучето трябва да остане
                  спокойно право, без да променя позицията си и да се оглежда.
                  След 30 крачки водачът спира и се обръща с лице към кучето. По
                  знак на съдията водачът извиква кучето си със съответната
                  команда или чрез името на кучето. Кучето трябва да дойде
                  въодушевено, бързо и направо при водача, като седне възможно
                  най-близо в права линия пред него. По команда на водача кучето
                  трябва бързо да седне от лявата му страна.
                  <br />
                  с) Оценяване: Изпълнение по различен от гореописания начин,
                  пристъпване след командата, неспокойно стоене, бавно идване
                  при водача, неправилна стойка на самия водач (разкрачени
                  крака), грешки при сядане при водача, както и некоректно
                  завършване на упражнението, се наказват съответно с отнемане
                  на точки. Ако кучето седне или легне след командата за
                  заставане на място, се отнемат 5 точки.
                  <br />
                  5. Апортиране на предмет (апортна гира) от равен терен - 10
                  точки
                  <br />
                  а) По една команда за: донасяне (Bring), пускане (Aus) и
                  заставане в основно положение (Fuss).
                  <br />
                  в) Изпълнение: От основно положение водачът хвърля апортната
                  гира (2000гр.) на около 10 крачки. Командата за донасяне се
                  дава едва след като предметът е паднал и лежи неподвижно на
                  земята. По команда на водача за донасяне кучето трябва да
                  отиде бързо към предмета, да го вземе без забавяне и веднага
                  да го донесе, като трябва да седне възможно най-близо и в
                  права линия пред водача. Кучето трябва да държи предмета в
                  уста спокойно, докато водачът го вземе от него след кратка
                  пауза (около 3 сек.) по команда за пускане. Апортната гира се
                  взима от водача и спокойно се държи с отпусната надолу ръка от
                  дясната страна на тялото. По команда на водача за основно
                  положение кучето трябва бързо и възможно най-близко да седне
                  от лявата му страна. От началото до края на упражнението
                  водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно отиване,
                  грешки при взимане на апортната гира, бавно връщане, изпускане
                  на предмета, игра или дъвчене на предмета, се наказват с
                  отнемане на точки. Близко хвърляне на апортната гира и помощ
                  от водача без да напуска своето място се наказват по същия
                  начин (отнемане на точки). Ако водачът се отмести от
                  първоначалното си положение, упражнението се оценява с
                  “незадоволителен”. Ако кучето не донесе предмета, упражнението
                  се оценява като неизпълнено и не се присъждат точки.
                  <br />
                  6. Апортиране на предмет с преминаване на препятствие 100см. -
                  15 точки
                  <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и заставане в основно положение (Fuss)
                  <br />
                  в) Изпълнение: Кучето и водачът застават в основно положение
                  на разстояние не по-малко от 5 крачки от препятствието. От
                  основно положение водачът хвърля апортната гира (650гр.) от
                  другата страна на високото 100 см. препятствие. Командата за
                  прескок се дава едва след като предметът лежи неподвижно на
                  земята. По команда на водача прескочи и донеси (командата
                  донеси трябва да се даде по време на прескока), кучето трябва
                  бързо да прескочи препятствието, веднага да вземе предмета, да
                  прескочи отново препятствието и да седне възможно най-близо и
                  в права линия пред водача.
                  <br />
                  Разпределение на точките:
                  <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени.
                  <br />
                  Прескоци и донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало. Кучето трябва да
                  държи предмета в устата си спокойно, докато водачът го вземе
                  от него след кратка пауза (3 сек.) по команда за пускане.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението, се наказват с
                  отнемане на точки. До 1 точка на прескок се отнема за
                  докосване на препятствието, а при удрянето му до 2т. Помощ от
                  водача без промяна на неговото основно положение се наказва с
                  отнемане на точки. Ако водачът напусне основното си положение
                  преди да е завършено окончателно упражнението, същото се
                  оценява като незадоволително.
                  <br />
                  7. Донасяне на предмет с преминаване на “А”-образно
                  препятствие (180см.) - 15точки
                  <br />
                  а) По една команда за: прескок (Норр), донасяне (Bring),
                  пускане (Aus) и заставане в основно положение (Fuss)
                  <br />
                  в) Изпълнение: Водачът и кучето заемат основно положение
                  най-малко на 5 крачки пред наклонената стена . От основно
                  положение водачът хвърля апортировъчния предмет (650 гр) над
                  наклонената стена. Командата за “скок” може да се даде едва
                  когато предметът лежи на земята. Кучето, спокойно и свободно
                  седящо до водача, след команда “скок” и команда “донеси”
                  (командата за донасяне на предмета трябва да бъде подадена по
                  време на скока) трябва да премине през стената, бързо и по
                  права линия стигне до апортировъчния предмет, незабавно да го
                  вдигне, да премине обратно и бързо да донесе предмета на
                  водача. Кучето трябва да седне пред водача близо и коректно и
                  спокойно да държи предмета в уста дотогава, докато водачът
                  след пауза от 3 сек. не го вземе от него с команда “дай”.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  Разпределение на точките:
                  <br />
                  отиване 5т.
                  <br />
                  взимане 5т.
                  <br />
                  връщане 5т.
                  <br />
                  Частично оценяване на упражнението е възможно, само ако
                  минимум две от трите части на същото са изпълнени.
                  <br />
                  Прескоци и донасяне без грешки - 15т.
                  <br />
                  Един от прескоците не е изпълнен, предметът е донесен без
                  грешки - 10т.
                  <br />
                  Прескоците изпълнени без грешки, предметът не е донесен - 10т.
                  <br />
                  Ако апортната гира е паднала странично от препятствието или е
                  невидима за кучето, след допитване до съдията и по негов знак
                  може същата да се хвърли отново без да се отнемат точки. През
                  това време кучето трябва да остане седнало. Кучето трябва да
                  държи предмета в устата си спокойно, докато водачът го вземе
                  от него след кратка пауза (3 сек.) по команда за пускане.
                  Апортната гира се взима от водача и спокойно се държи с
                  отпусната надолу ръка от дясната страна на тялото. По команда
                  на водача за основно положение кучето трябва бързо и възможно
                  най-близо да седне от лявата му страна. От началото до края на
                  упражнението водачът трябва да стои в основно положение.
                  <br />
                  с) Оценяване: Грешки в основното положение, бавно скачане и
                  отиване към предмета, грешки при взимането му, бавно връщане,
                  изпускане на предмета, игра или дъвчене на същия, неправилна
                  стойка на водача (разкрачени крака), грешки при сядане пред
                  водача, както и при завършване на упражнението, се наказват с
                  отнемане на точки. Помощ от водача без промяна на неговото
                  основно положение се наказва с отнемане на точки. Ако водачът
                  напусне основното си положение преди да е завършено
                  окончателно упражнението, същото се оценява като
                  незадоволително.
                  <br />
                  8. Изпращане напред с лягане - 10 точки
                  <br />
                  а) По една команда за: изпращане напред (Voraus), лягане
                  (Platz), и сядане (Sitz).
                  <br />
                  в) Изпълнение: от основно положение водачът със свободно
                  следващо го куче върви по права линия в указаната му посока.
                  След 10-15 крачки водачът, едновременно с еднократно вдигане
                  на ръка, дава на кучето команда “напред” и остава на място.
                  Кучето трябва целеустремено и бързо да тича в указаната посока
                  минимум 30 крачки. По знак на съдията водачът дава команда
                  ”легни”, след която кучето трябва незабавно да легне. Водачът
                  може да държи ръката си вдигната до момента, в който кучето
                  легне. По знак на съдията водачът отива към своето куче и
                  заема позиция в дясно от него. След около 3 сек и по знак на
                  съдията, на кучето се дава команда “седни”. Кучето трябва
                  бързо и коректно да заеме основно положение.
                  <br />
                  с) Оценяване: грешки по време на упражнението, движение на
                  водача след подадената команда, много бавно тичане напред,
                  силно отклонение в страни, много късо разстояние, бавно или
                  преждевременно лягане, неспокойно поведение по време на
                  лежането и преждевременно ставане от място довеждат до
                  отнемане на точки.
                  <br />
                  9. Престой с отвличане на вниманието - 10 точки
                  <br />
                  а) По една команда за: лягане (Platz) и сядане (Sitz).
                  <br />
                  в) Изпълнение: в началото на изпълнението на упражненията от
                  раздел “В” на друго куче, водачът поставя своето куче легнало
                  с команда ”легни” на указаното от съдията място. Кучето ляга
                  от основно положение, като при това до него не трябва да се
                  оставя повод или други предмети. Водачът, без да се обръща, се
                  отдалечава от кучето минимум на 30 крачки и застава извън
                  полезрението на кучето. Кучето трябва да остане да лежи
                  спокойно, без каквото и да било въздействие от страна на
                  водача, докато друго куче демонстрира упражненията от 1 до 7.
                  По знак на съдията водачът се връща при своето куче и заема
                  позиция вдясно от него. След около 3 сек и по знак на съдията,
                  на кучето се подава команда “седни”. Кучето трябва бързо и
                  коректно да заеме основно положение.
                  <br />
                  с) Оценяване: неспокойно поведение на водача, а също така и
                  друга помощ, неспокойно поведение на кучето по време на
                  престоя, преждевременно ставане от място при идване на водача,
                  водят до отнемане на точки. Ако кучето остане седнало или
                  право, но без да напуска мястото, се дава непълна оценка. Ако
                  кучето се отдалечи от мястото на повече от 3 метра преди
                  завършване на упражнение 5 на другото куче, упражнението се
                  оценява с 0 точки. Ако кучето напусне мястото си след
                  завършване на упражнение 5 на другото куче, тогава се дава
                  непълна оценка. Ако кучето се движи срещу водача, когато той
                  идва да го вземе, се отнемат до 3 точки.
                  <br />
                  <br />
                  Дисциплина С - "ЗАЩИТА" <br />
                  Упражнение: <br />
                  1. Търсене на фигуранта - 10т. <br />
                  2. Охрана и облайване - 10т. <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 10т.{" "}
                  <br />
                  4. Защита след нападение от фаза на охраняване - 20т. <br />
                  5. Конвой - 5т. <br />
                  6. Нападание на кучето от конвоя - 15т. <br />
                  7. Нападение на кучето от движение - 10т. <br />
                  8. Защита след нападение от фаза на охраняване - 20т. <br />
                  Общо: 100 точки <br />
                  Общи условия <br />
                  На подходящо място са разположени по дължина 6 укрития - по 3
                  укрития на всяка страна. Необходимите означения трябва да са
                  добре видими за водача, съдията и фигуранта. <br />
                  Фигурантът трябва да е снабден със защитен костюм, защитен
                  ръкав и мека палка. Ръкавът трябва да притежава надебеление за
                  захапване, а калъфът да е изготвен от юта с естествен цвят.
                  Ако за фигуранта е необходимо да следи кучето с поглед, не е
                  нужно той да стои неподвижно. Не е позволено обаче да извършва
                  заплашителни движения. Фигурантът трябва да покрива тялото си
                  с ръкава. Начинът на отнемане на палката на фигуранта от
                  водача се избира от водача. <br />
                  При изпити може да се работи с един фигурант. При над 6 кучета
                  в изпитна степен обаче са нужни двама фигуранти. В рамките на
                  една изпитна степен всички водачи трябва да работят с един и
                  същ/едни и същи фигуранти. Кучета, които не могат да бъдат
                  контролирани от водача си, които не пускат или пускат само
                  чрез голяма помощ от страна на водача, които захапват други
                  части на тялото освен определения за тази цел ръкав, трябва да
                  бъдат дисквалифицирани. <br />
                  Не се присъжда «TSB»-оценка. При кучета, които отказват
                  изпълнението на някое упражнение от защитата или се оставят да
                  бъдат прогонени, дисциплина С трябва да бъде прекъсната.
                  Оценяване не се извършва. Дава се само «TSB»-оценка. Команда
                  “Пусни!” във всички упражнения е позволено да се подава само
                  веднъж. <br />
                  Оценяване на пускането (наказателни точки):
                  <br />
                  колебливо пускане 0,5-3,0 н.т.
                  <br />
                  първа допълнителна команда с незабавно пускане 3,0 н.т.
                  <br />
                  първа допълнителна команда с колебливо пускане 3,5-6,0 н.т.
                  <br />
                  втора допълнителна команда с незабавно пускане 6,0 н.т.
                  <br />
                  втора допълнителна команда с колебливо пускане 6,5-9,0 н.т.
                  <br />
                  кучето не пуска и след втората допълнителна команда -
                  дисквалификация
                  <br />
                  1. Търсене на фигуранта - 10 точки
                  <br />
                  a) Команди за: “претърсване” и “идване” (командата за идване
                  може да е свързана и с името на кучето).
                  <br />
                  в) Изпълнение: Фигурантът се намира, невидим за кучето, в
                  последното укритие. Водачът взима позиция със свободно
                  следващо го куче пред първото укритие, така че да е възможно
                  обикалянето на шест укрития. По знак на съдията се дава
                  началото на дисциплина С. След кратка команда за претърсване и
                  насочване с лявата или дясната ръка кучето трябва бързо да се
                  отдели от водача и целеустремено да се насочи към посоченото
                  укритие и внимателно и точно да го заобиколи. Ако кучето
                  изпълни това, водачът го извиква към себе си с кратка команда
                  за идване и с нова команда за претърсване от движение го
                  отпраща към следващото укритие. Водачът се движи с нормален
                  ход по мислената средна линия, която той не трябва да напуска
                  по време на претърсването. Кучето винаги трябва да се намира
                  пред водача. Когато кучето достигне до укритието с фигуранта,
                  водачът трябва да застане на едно място. Команди и насочване с
                  ръка вече не са позволени.
                  <br />
                  2. Охрана и облайване - 10 точки
                  <br />
                  a) Команди за: “идване” и “заставане в основно положение”.
                  <br />
                  b) Изпълнение: Кучето трябва да охранява фигуранта активно и
                  внимателно и да го облайва продължително и старателно. Кучето
                  не трябва да скача по фигуранта и да докосва ръкава. След
                  продължителност на облайването от около 20 секунди по знак на
                  съдията водачът се приближава на 5 крачки пред укритието. По
                  знак на съдията водачът подава команда на кучето си за
                  заставане в основно положение.
                  <br />
                  3. Предотвратяване на опит за бягство на фигуранта - 10 точки
                  <br />
                  a) Команди за: “ходене на ред”, “лягане” и “пускане”
                  <br />
                  b) Изпълнение: По знак на съдията водачът принуждава фигуранта
                  да излезе от укритието. Фигурантът с нормален ход се отправя
                  към обозначената за опита за бягство изходна позиция. По знак
                  на съдията водача със свободно следващо го куче се отправя към
                  обозначената за опита за бягство позиция за лягане.
                  Разстоянието между фигуранта и кучето трябва да е 5 крачки.
                  Водачът подава на кучето команда за лягане и се отправя към
                  укритието. Той има зрителен контакт към кучето си, фигуранта и
                  съдията. По знак на съдията фигурантът предприема опит за
                  бягство. Кучето трябва без да се колебае самостоятелно да
                  осуети опита за бягство чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. По знак на съдията
                  фигурантът застава неподвижен на едно място, при което кучето
                  веднага трябва да пусне. Водачът в подходящо време може да
                  подаде еднократна команда за пускане. Ако кучето не пусне след
                  първата позволена команда, водачът получава знак от съдията за
                  подаване на до две допълнителни команди. Ако кучето не пусне и
                  след трите команди (една позволена и две допълнителни), следва
                  дисквалификация. По време на подаване на командите водачът
                  трябва да стои спокойно без да въздейства по друг начин върху
                  кучето. След пускането кучето трябва да остане плътно до
                  фигуранта и да го охранява внимателно.
                  <br />
                  4. Защита след нападение от фаза на охраняване - 20 точки
                  <br />
                  a) Команди за: “пускане” и “заставане в основно положение”
                  <br />
                  b) Изпълнение: След фаза на охраняване, продължаваща около 5
                  секунди, фигурантът предприема по знак на съдията нападение
                  срещу кучето. Без въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Ако кучето захапе,
                  му се нанасят два удара. Допускат се единствено удари по
                  раменете и областта на холката. По знак на съдията фигурантът
                  застава неподвижен на едно място, при което кучето веднага
                  трябва да пусне. Водачът в подходящо време може да подаде
                  еднократна команда за пускане. Ако кучето не пусне след
                  първата позволена команда, водачът получава знак от съдията за
                  подаване на до две допълнителни команди. Ако кучето не пусне и
                  след трите команди (една позволена и две допълнителни), следва
                  дисквалификация. По време на подаване на командите водачът
                  трябва да стои спокойно без да въздейства по друг начин върху
                  кучето. След пускането кучето трябва да остане плътно до
                  фигуранта и да го охранява внимателно. По знак на съдията
                  водачът в нормален ход отива до кучето си и чрез команда за
                  “заставане в основно положение” го поставя в основно
                  положение. Палката не се взима от фигуранта.
                  <br />
                  5. Конвой - 5 точки
                  <br />
                  a) Команди за: “ходене на ред”.
                  <br />
                  в) Изпълнение: След упражнение 4 следва конвой на фигуранта с
                  продължителност от около 30 крачки. Протичането на конвоя се
                  определя от съдията. Водачът принуждава фигуранта да върви
                  напред и тръгва със свободно следващо го на ред и внимателно
                  наблюдаващо фигуранта куче на дистанция от 5 крачки зад
                  фигуранта. Дистанцията от 5 крачки трябва да се спазва по
                  време на целия конвой.
                  <br />
                  6. Нападание на кучето от конвоя - 15 точки
                  <br />
                  a) Команди за: “пускане” и “ходене на ред”.
                  <br />
                  в) Изпълнение: От конвоя по знак на съдията следва нападение
                  срещу кучето. Без въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Когато кучето
                  захапе, водачът трябва веднага да застане на едно място. По
                  знак на съдията фигурантът прекратява нападението, след което
                  кучето незабавно трябва да пусне. Водачът в подходящо време
                  може да подаде еднократна команда за пускане. Ако кучето не
                  пусне след първата позволена команда, водачът получава знак от
                  съдията за подаване на до две допълнителни команди. Ако кучето
                  не пусне и след трите команди (една позволена и две
                  допълнителни), следва дисквалификация. По време на подаване на
                  командите водачът трябва да стои спокойно без да въздейства по
                  друг начин върху кучето. След пускането кучето трябва да
                  остане плътно до фигуранта и да го охранява внимателно. По
                  знак на съдията водачът в нормален ход отива до кучето си и
                  чрез команда за “заставане в основно положение” го поставя в
                  основно положение. Палката се взима от фигуранта.
                  <br />
                  Следва успореден конвой на фигуранта до съдията с
                  продължителност от около 20 крачки. Позволена е една команда
                  за "ходене на ред". Кучето трябва да върви отдясно на
                  фигуранта, така че да се намира между водача и фигуранта. По
                  време на конвоя кучето трябва внимателно да наблюдава
                  фигуранта. Не е позволено обаче кучето да се бута във
                  фигуранта, да скача по него или да захапва ръкава. Групата
                  спира пред съдията, водачът предава палката на съдията и
                  обявява първата част на дисциплина С за приключена.
                  <br />
                  7. Нападение на кучето от движение - 10 точки
                  <br />
                  a) Команди за: “сядане”, “нападане”, “пускане”.
                  <br />
                  b) Изпълнение: Водачът се насочва със свободно следващо го
                  куче към маркираното място в средната линия на нивото на
                  първото укритие. Кучето може да бъде хванато за нашийника, но
                  не е позволено да бъде допълнително стимулирано от водача. По
                  знак на съдията от някое укритие излиза снабдения с мека палка
                  фигурант и се насочва тичайки към средната линия. На нивото на
                  средната линия фигурантът без да намалява темпото си се обръща
                  към водача и напада фронтално кучето и водача чрез прогонващи
                  викове и застрашителни движения. Когато фигурантът се приближи
                  до кучето и водача на 60 крачки, по знак на съдията водачът
                  освобождава кучето си и му подава команда за “нападане”.
                  Кучето трябва да отблъсне нападението без да се колебае чрез
                  енергично и силно захапване. На кучето е позволено да хапе
                  само по ръкава. Водачът не трябва да напуска указаното му
                  място. По знак на съдията фигурантът застава неподвижен на
                  едно място, при което кучето веднага трябва да пусне. Водачът
                  в подходящо време може да подаде еднократна команда за
                  пускане. Ако кучето не пусне след първата позволена команда,
                  водачът получава знак от съдията за подаване на до две
                  допълнителни команди. Ако кучето не пусне и след трите команди
                  (една позволена и две допълнителни), следва дисквалификация.
                  По време на подаване на командите водачът трябва да стои
                  спокойно без да въздейства по друг начин върху кучето. След
                  пускането кучето трябва да остане плътно до фигуранта и да го
                  охранява внимателно.
                  <br />
                  8. Защита след нападение от фаза на охраняване - 20 точки
                  <br />
                  a) Команди за: “пускане”, “заставане в основно положение” и
                  “ходене на ред”.
                  <br />
                  b) Изпълнение: След фаза на охраняване, продължаваща около 5
                  секунди, фигурантът предприема по знак на съдията нападение
                  срещу кучето. Без въздействие от страна на водача кучето
                  трябва да се защити чрез енергично и силно захапване. На
                  кучето е позволено да хапе само по ръкава. Ако кучето захапе,
                  му се нанасят два удара. Допускат се единствено удари по
                  раменете и областта на холката. По знак на съдията фигурантът
                  застава неподвижен на едно място, при което кучето веднага
                  трябва да пусне. Водачът в подходящо време може да подаде
                  еднократна команда за пускане.
                  <br />
                  Ако кучето не пусне след първата позволена команда, водачът
                  получава знак от съдията за подаване на до две допълнителни
                  команди. Ако кучето не пусне и след трите команди (една
                  позволена и две допълнителни), следва дисквалификация. По
                  време на подаване на командите водачът трябва да стои спокойно
                  без да въздейства по друг начин върху кучето. След пускането
                  кучето трябва да остане плътно до фигуранта и да го охранява
                  внимателно. По знак на съдията водачът в нормален ход отива до
                  кучето си и чрез команда за “заставане в основно положение” го
                  поставя в основно положение. Палката се взима от фигуранта.
                  <br />
                  Следва успореден конвой на фигуранта до съдията с
                  продължителност от около 20 крачки. Позволена е една команда
                  за "ходене на ред". Кучето трябва да върви отдясно на
                  фигуранта, така че да се намира между водача и фигуранта. По
                  време на конвоя кучето трябва внимателно да наблюдава
                  фигуранта. Не е позволено обаче кучето да се бута във
                  фигуранта, да скача по него или да захапва ръкава. Групата
                  спира пред съдията, водачът предава палката на съдията и
                  обявява дисциплина С за приключена. Преди обявяването на
                  резултатите и по знак на съдията кучето се поставя на повод.{" "}
                  <br />
                </Card.Text>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};
